<div class="container m-t-30" *ngIf="email_telefono && email_telefono != ''">
    <div class="row">
        <div class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12">
            <div class="block">
                <div class="block-title"><h2>Cambia tus preferencias de suscripción</h2></div>
                <div class="row row-equal-cols">
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <div style="padding: 10px 0">
                            <label class="font-normal"><input type="checkbox" class="chk-todas" value="" (change)="ChangeTodas()"> Todas</label>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let item of categorias">
                        <div style="padding: 10px 0">
                            <label class="font-normal"><input type="checkbox" class="chk-cat" [value]="item.id" [checked]="item.checked" (change)="ChangeCheck()"> {{item.categoria}}</label>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <a class="btn btn-primary" (click)="GuardarPreferencias()">Guardar preferencias</a>
                </div>
                <hr style="margin: 10px 0;">
                <div class="text-center">
                    <p style="margin: 5px 0;">Si deseas dar de baja tu suscripción pincha en el siguiente botón</p>
                    <a class="btn btn-default" (click)="CancelarSuscripcion()">Cancelar todas las suscripciones</a>
                </div>
                <hr style="margin: 10px 0;">
                <div *ngIf="config && config.nombre" class="font-16 font-bold m-b-20 text-center">{{config.nombre}}</div>
            </div>
        </div>
    </div>
</div>
<div class="container m-t-30" *ngIf="!email_telefono || email_telefono == ''">
    <h2 class="text-center font-20">Lo sentimos, no hemos encontrado tu suscripción</h2>
</div>