import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let $: any;
declare let Swiper: any;

@Component({
  selector: 'app-sugerencias-front',
  templateUrl: './sugerencias-front.component.html',
  styleUrls: ['./sugerencias-front.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SugerenciasFrontComponent implements OnInit {
  public id_cliente:string = (this.globals.GetQueryString('cliente') ? this.globals.GetQueryString('cliente') : '');
  public css:string = (this.globals.GetQueryString('css') ? this.globals.GetQueryString('css') : '');
  public titulo:string = (this.globals.GetQueryString('titulo') ? this.globals.GetQueryString('titulo') : 'Recomendación de la semana:');
  public vista:string = (this.globals.GetQueryString('vista') ? this.globals.GetQueryString('vista') : '');
  public sugerencias:any = [];

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Sugerencias semanales');
    this.globals.Api('/pub-mod-sugerencias', {id_cliente: this.id_cliente}).subscribe(data => {
      if (!data || !data.length) return;
      this.sugerencias = data;
    });
    if (this.vista == 'slider') {
      $(document).ready(() => {
        this.CargarSlider();
      });
    }
    setTimeout(() => {
      if (this.css && this.css != '') {
        var styleSheet = document.createElement("style");
        styleSheet.innerText = this.css;
        document.body.appendChild(styleSheet);
      }
    }, 100);
  }
  CargarSlider() {
    if ($.swiper) return;
    $.swiper = new Swiper('.swiper-sugerencias', {
      direction: 'horizontal',
      loop: false,
      // loopFillGroupWithBlank: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 6,
      spaceBetween: 20,
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 6,
          spaceBetween: 20
        }
      }
    });
    setTimeout(() => {if ($.swiper) $('.vista-slider').show();$.swiper.update();}, 10);
    setTimeout(() => {if ($.swiper) $('.vista-slider').show();$.swiper.update();}, 100);
    setTimeout(() => {if ($.swiper) $('.vista-slider').show();$.swiper.update();}, 500);
  }

}
