<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Calendario</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarCalendario()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Calendario</li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva reserva</a>
                        </div>
                    </div>
                </div>
                
                
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-xs-12">
                        <div class="block full">
                            <div class="block-title"><h2>Alojamientos</h2></div>
                            <div class="form-group">
                            <input type="text" class="form-control" placeholder="Buscar alojamiento ..." [(ngModel)]="filtroalojamiento" (input)="BuscarAlojamiento($event)">
                            </div>
                            <ul class="nav nav-pills nav-stacked" style="max-height:80vh;overflow:auto;">
                                <li id="todos-alojamientos" class="active">
                                    <a class="cursor" (click)="SetTodos()"><i class="icofont-user"></i> Todos</a>
                                </li>
                                <li class="{{item.active ? 'active' : null}}" *ngFor="let item of alojamientos;let i = index" (click)="SetAlojamiento(item)">
                                    <a href="javascript:void(0)"><i class="icofont-user"></i> {{item.nombre}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-xs-12">
                        <div class="block full calendar-container">
                            <div class="block-title"><h2>Calendario de <span class="text-primary">{{alojamiento}}</span></h2></div>
                            <div id="calendar"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>