<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <div *ngIf="data && data.id && data.id != ''" class="font-12 cursor" (click)="Portapapeles()">ID: {{data.id}}</div>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/clientes-spa']">Clientes</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                            <a class="btn btn-warning m-r-30" (click)="VistaCliente()" *ngIf="data && data.id && data.id != ''"><i class="icofont-ui-user-group m-r-10"></i>Entrar en vista de cliente</a>
                            <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                            <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">Datos generales</a></li>
                            <li class="{{!data || !data.id || data.id == '' ? 'hidden' : null}}"><a href="#tab-personalizacion"><i class="icofont-paint"></i> Personalización</a></li>
                            <li class="{{!data || !data.id || data.id == '' ? 'hidden' : null}}"><a href="#tab-correo"><i class="icofont-envelope"></i> Correo</a></li>
                            <!-- <li class="{{!data || !data.id || data.id == '' ? 'hidden' : null}}"><a href="#tab-pago"><i class="icofont-credit-card"></i> Pago</a></li> -->
                            <li class="{{!data || !data.id || data.id == '' ? 'hidden' : null}}"><a href="#tab-wordpress"><i class="icofont-brand-wordpress"></i> WordPress</a></li>
                            <!-- <li class="{{!data || !data.id || data.id == '' ? 'hidden' : null}}"><a href="#tab-prev-login"><i class="icofont-eye-alt"></i> Previsualización</a></li> -->
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">
                              
                            <div class="row">
                              <div class="col-lg-4 col-md-12">
                                <div *ngIf="(!data.imagen || data.imagen == '') && (!data.img_externa || data.img_externa == '')" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                                  <i class="icofont-camera-alt font-50"></i>
                                </div>
                                <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                                  <img *ngIf="!data.img_externa && data.imagen && data.imagen != ''" [src]="data.imagen">
                                  <img *ngIf="data.img_externa && data.img_externa != ''" [src]="data.img_externa">
                                  <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                                </div>
                                <div class="form-group m-t-10">
                                  <label>Imagen desde url externa</label>
                                  <input type="url" class="form-control url-paste" [(ngModel)]="data.img_externa" maxlength="400" placeholder="Pega aquí la url o imagen">
                                </div>
                                <hr>
                                <div class="form-group">
                                    <label>Serparar eventos permanentes</label>
                                    <select class="form-control" [(ngModel)]="data.config.separar_eventos_permanentes" style="width:auto;">
                                        <option value="1">Sí</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                              </div>
                              <div class="col-lg-8 col-md-12">
                                
                                <div class="row m-t-10">
                                    <div class="col-md-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-primary">Tarifa <span class="text-danger">*</span></label>
                                            <select class="form-control font-bold font-20" placeholder="Tarifa" [(ngModel)]="data.id_tarifa" style="height:40px;">
                                                <option *ngFor="let item of tarifas" [value]="item.id">{{item.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8 col-xs-12">
                                  <div class="form-group">
                                      <label class="text-primary">Empresa <span class="text-danger">*</span></label>
                                      <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                                  </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                      <label>NIF / CIF</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.nifcif" maxlength="20">
                                  </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary">Nombre <span class="text-danger">*</span></label>
                                      <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary">Apellidos <span class="text-danger">*</span></label>
                                      <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5 col-xs-12">
                                    <div class="form-group">
                                      <label>Direcci&oacute;n</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                                    </div>
                                  </div>
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>C&oacute;digo postal</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                                        (change)="DatosLocalidad()">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Localidad</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>Provincia</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>Tel&eacute;fono</label>
                                      <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-xs-12">
                                      <div class="form-group">
                                        <label class="text-primary"><i class="fa fa-envelope"></i> Email <span class="text-danger">*</span></label>
                                        <input type="email" class="form-control"[(ngModel)]="data.email" maxlength="80">
                                      </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <h4 class="font-bold">Clave de licencia</h4>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="data.licencia_key" maxlength="80">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <h4 class="font-bold"><i class="icofont-plugin"></i> Módulos</h4>
                                    <div class="form-group">
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <label class="display-block">
                                                    <input type="checkbox" class="chk-modulo" 
                                                    slug="reservas-restaurante" 
                                                    icono="icofont-dining-table"
                                                    nombre="Reservas"
                                                    [attr.checked]="GetCheckedModulo('reservas-restaurante')">
                                                    <span class="m-l-10">Reservas <i class="icofont-dining-table"></i></span>
                                                </label>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="display-block">
                                                    <input type="checkbox" class="chk-modulo" 
                                                    slug="sugerencias" 
                                                    icono="icofont-restaurant"
                                                    nombre="Sugerencias"
                                                    [attr.checked]="GetCheckedModulo('sugerencias')">
                                                    <span class="m-l-10">Sugerencias <i class="icofont-restaurant"></i></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <ng-container *ngIf="data && data.usuarios">
                              <div class="">
                                <h4 class="font-bold">Usuarios de acceso al sistema</h4>
                                <div class="table-responsive table-grid block full">
                                  <table class="table table-striped table-bordered table-vcenter table-hover">
                                      <thead>
                                          <tr>
                                            <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                                            <th style="width:50px"></th>
                                            <th style="width:200px" order="nombre">Nombre</th>
                                            <th style="width:130px" order="alta">Alta</th>
                                            <th order="rol">Rol</th>
                                            <th order="localidad">Localidad</th>
                                            <th order="telefono">Tel&eacute;fono</th>
                                            <th order="email">Email</th>
                                            <th style="width:50px">Acceso</th>
                                            <th style="width:50px">Editar</th>
                                            <th style="width:50px">Borrar</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let item of data.usuarios" class="cursor">
                                            <!-- <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></td> -->
                                            <td class="text-center" (click)="EditarUsuario(item)">
                                              <img *ngIf="item.imagen" [src]="item.imagen" class="img-usuario img-circle square">
                                              <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i>
                                            </td>
                                            <td class="font-bold text-primary" (click)="EditarUsuario(item)">
                                              <div>{{item.nombre}} {{item.apellidos}}</div>
                                            </td>
                                            <td>{{item.alta_format}}</td>
                                            <td>{{item.rol}}</td>
                                            <td>{{item.localidad}}</td>
                                            <td><span *ngIf="item.telefono" class="no-wrap"><i class="fa fa-phone"></i> {{item.telefono}}</span></td>
                                            <td class="breaktd"><span *ngIf="item.email" class="no-wrap"><i class="fa fa-envelope"></i> {{item.email}}</span></td>
                                            <td class="text-center cursor btntd" (click)="CambiarAcceso(item)">
                                              <a *ngIf="item.acceso"><i class="fa fa-check text-success"></i></a>
                                              <a *ngIf="!item.acceso"><i class="fa fa-close text-danger"></i></a>
                                            </td>
                                            <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="EditarUsuario(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                            <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="BorrarUsuario(item)"><i class="fa fa-trash text-white"></i></a></td>
                                          </tr>
                                      </tbody>
                                  </table>
                                </div>
                              </div>
                            </ng-container>
                        </div>
                        <div class="tab-pane" id="tab-personalizacion">
                          <div class="row">
                            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                              <div class="form-group">
                                  <label>Nombre público</label>
                                  <input type="text" class="form-control" [(ngModel)]="data.config.nombre" maxlength="80">
                              </div>
                              <div class="form-group">
                                  <label>URL cliente</label>
                                  <input type="url" class="form-control" [(ngModel)]="data.config.url_cliente" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label>URL carta</label>
                                  <input type="url" class="form-control" [(ngModel)]="data.config.url_carta" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label>URL política de cancelación</label>
                                  <input type="url" class="form-control" [(ngModel)]="data.config.url_politica_cancelacion" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label><i class="icofont-image text-primary"></i> URL imagen fondo login</label>
                                  <img *ngIf="data.config.bg_login" [src]="data.config.bg_login" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                  <input type="url" class="form-control url-paste" [(ngModel)]="data.config.bg_login" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label><i class="icofont-image text-primary"></i> URL imagen logo login</label>
                                  <img *ngIf="data.config.logo_login" [src]="data.config.logo_login" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                  <input type="url" class="form-control url-paste" [(ngModel)]="data.config.logo_login" maxlength="400">
                              </div>
                              <div class="form-group">
                                  <label><i class="icofont-image text-primary"></i> URL imagen logo menú</label>
                                  <img *ngIf="data.config.logo_menu" [src]="data.config.logo_menu" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                  <input type="url" class="form-control url-paste" [(ngModel)]="data.config.logo_menu" maxlength="400">
                              </div>
                            </div>
                            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                              <label>CSS Personalizado</label>
                              <div id="txtcustomcss" [innerHTML]="data.config.customcss" style="height:500px;"></div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tab-correo">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo salida</h2></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Host</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.config.emailconf_host" maxlength="80">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Port</label>
                                                    <input type="number" class="form-control" [(ngModel)]="data.config.emailconf_port" maxlength="12">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>SSL</label>
                                                    <select class="form-control" [(ngModel)]="data.config.emailconf_ssl">
                                                    <option value="1">Sí</option>
                                                    <option value="0" selected>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Email para env&iacute;os</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.config.emailconf_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Contraseña de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.emailconf_pass" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Alias de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.emailconf_alias" maxlength="80">
                                        </div>
                                        <hr>
                                        <div class="form-group">
                                            <label>T&iacute;tulo cabecera email</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.emailconf_titulo_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label><i class="icofont-image text-primary"></i> URL imagen logo</label>
                                            <img *ngIf="data.config.emailconf_logo" [src]="data.config.emailconf_logo" class="display-block m-t-10 m-b-10" style="max-height:80px;background-color: #F1F1F1;">
                                            <input type="url" class="form-control url-paste" [(ngModel)]="data.config.emailconf_logo" maxlength="400">
                                        </div>
                                        <div class="form-group">
                                            <label><i class="icofont-color-bucket text-primary"></i> Color BG Cabecera</label>
                                            <input type="color" class="form-control" [(ngModel)]="data.config.emailconf_bgcolor_cabecera" maxlength="20">
                                        </div>
                                        <div class="form-group">
                                            <label><i class="icofont-color-bucket text-primary"></i> Color texto destacado</label>
                                            <input type="color" class="form-control" [(ngModel)]="data.config.emailconf_color_destacado" maxlength="20">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo administrador</h2></div>
                                        <div class="form-group">
                                            <label>Alias email administrador</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.emailconf_emailadmin_alias" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 1</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.config.emailconf_emailadmin1" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 2</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.config.emailconf_emailadmin2" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 3</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.config.emailconf_emailadmin3" maxlength="80">
                                        </div>
                                    </div>
                                    <div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="EmailTest()"><i class="icofont-envelope"></i> Enviar Test</a>
                                    </div>

                                    <div class="block">
                                        <div class="block-title"><h2><i class="fa fa-whatsapp"></i> Notificaciones WhatsApp</h2></div>
                                        
                                        <div class="form-group">
                                            <label>Facebook ID</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.whatsapp_fbid" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Facebook ID Account</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.whatsapp_fbid_account" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Facebook Access Token</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.whatsapp_access_token" maxlength="400">
                                        </div>
                                    </div>
                                    <div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="EmailTestWhatsApp()"><i class="fa fa-whatsapp"></i> Enviar Test</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-pago">
                            <div class="block">
                                <div class="block-title"><h2>Formas de pago</h2></div>
                                <ng-container *ngFor="let item of data.formas_pago">
                                    <div class="row m-b-10">
                                        <div class="col-md-3 col-xs-12">
                                            <div class="font-bold m-b-10">{{item.forma_pago}}</div>
                                            <div>
                                                <label class="switch switch-success switch-sm m-r-10">
                                                    <div style="font-weight:normal;">Visible web</div>
                                                    <input type="checkbox" [(ngModel)]="item.visible"><span></span>
                                                </label>
                                                <!-- <label class="switch switch-warning switch-sm">
                                                    <div style="font-weight:normal;">Bizum</div>
                                                    <input type="checkbox" [(ngModel)]="item.bizum"><span></span>
                                                </label> -->
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-xs-12">
                                            {{item.slug}}
                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                            <textarea type="text" class="form-control text-editor" 
                                            placeholder="Introduce aquí el texto que se incluirá en el email" [(ngModel)]="item.descripcion_email" rows="4"></textarea>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="item.datos">
                                        <div class="m-b-30">
                                            <ng-container *ngIf="item.slug == 'paypal'">
                                                <a class="btn btn-default m-b-10" data-toggle="collapse" href="#config-paypal" role="button" aria-expanded="false">
                                                    Configuración Paypal <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-paypal" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Email</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.email" maxlength="80">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Token</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.token" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="switch switch-warning switch-sm">
                                                                <div style="font-weight:normal;">Entorno pruebas</div>
                                                                <input type="checkbox" [(ngModel)]="item.datos.entorno_pruebas"><span></span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Moneda</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.moneda" maxlength="10">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Url Retorno</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_retorno" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Cancelación</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_cancelacion" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Notificación IPN</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_notificacion_ipn" maxlength="400">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="item.slug == 'tarjeta'">
                                                <a class="btn btn-default m-b-10" data-toggle="collapse" href="#config-tarjeta" role="button" aria-expanded="false">
                                                    Configuración Tarjeta (RedSys) <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-tarjeta" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Sistema</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.sistema" maxlength="10">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Nombre comercio</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.nombre_comercio" maxlength="80">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>FUC comercio</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.fuc_comercio" maxlength="50">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Clave SHA256</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.clave_sha256" maxlength="50">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Terminal</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.terminal" maxlength="4">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Moneda</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.moneda" maxlength="10">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Retorno</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_retorno" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Error</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_error" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Notificación IPN</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_notificacion_ipn" maxlength="400">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-wordpress">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Conexión base de datos WordPress</h2></div>
                                        <div class="form-group">
                                            <label>Conectar</label>
                                            <label class="switch switch-success switch-sm m-l-10">
                                                <input type="checkbox" [(ngModel)]="data.config.wp_db_conectar"><span></span>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>URL Eventos</label>
                                            <input type="url" class="form-control" [(ngModel)]="data.config.wp_url_eventos" maxlength="50">
                                        </div>
                                        <div class="form-group">
                                            <label>Host</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.config.wp_db_host" maxlength="200">
                                        </div>
                                        <div class="form-group">
                                            <label>Nombre</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.wp_db_name" maxlength="50">
                                        </div>
                                        <div class="form-group">
                                            <label>Usuario</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.wp_db_user" maxlength="50">
                                        </div>
                                        <div class="form-group">
                                            <label>Contrase&ntilde;a</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.wp_db_pass" maxlength="50">
                                        </div>
                                        <div class="form-group">
                                            <label>Prefijo tablas</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.config.wp_db_prefix" maxlength="50">
                                        </div>
                                        <div class="form-group">
                                            <a id="btn-wordpress-conexion" class="btn btn-default btn-block cursor" (click)="TestWPConexion()">
                                                Probar conexión
                                            </a>
                                        </div>
                                        <div class="form-group">
                                            <a id="btn-regenerar-custom-posts" class="btn btn-primary btn-block cursor" (click)="GenerarWPCustomPosts()">
                                                <i class="icofont-refresh"></i>
                                                Generar eventos en WordPress (Custom Posts)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <ul class="list-group" *ngIf="wp_tablas && wp_tablas.length">
                                        <li class="list-group-item" *ngFor="let item of wp_tablas">{{item.table_name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane" id="tab-prev-login">
                            <iframe [src]="iframe" frameborder="0" style="width:100%;height:70vh;"></iframe>
                        </div> -->
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>