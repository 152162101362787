<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva sugerencia</a>
                        </div>
                    </div>
                </div>

                <div id="sugerencias-list" class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 col" *ngFor="let item of data" [attr.itemid]="item.id">
                        <a href="javascript:void(0)" class="widget widget-sugerencia" (click)="Editar(item)">
                            <div class="widget-content widget-content-mini ">
                                <strong class="text-dark font-20" style="line-height:1.2;">{{item.titulo}}</strong>
                            </div>
                            <div class="widget-content text-right clearfix">
                                <a class="btn btn-xs btn-danger" (click)="Borrar(item, $event)"><i class="fa fa-trash"></i></a>
                                <img [src]="item.imagen" *ngIf="item.imagen" class="width-100">
                                <div class="m-t-10" [innerHTML]="item.descripcion"></div>
                                <h2 class="widget-heading h3 text-primary"><strong>{{item.precio_format}} € <span *ngIf="item.precio_sufijo" class="font-16" style="font-weight:normal;">{{item.precio_sufijo}}</span></strong></h2>
                                <a class="btn btn-default btn-block"><i class="fa fa-pencil"></i> Editar</a>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="no-resultados" *ngIf="!data || !data.length">
                    <div>Aún no se han creado sugerencias. Crea tu primera sugerencia:</div>
                    <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir sugerencia</a>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal fade" id="modal-crear-sugerencia" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title"><i class="icon-list"></i> Nueva sugerencia</h4>
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Título <span class="text-danger">*</span></label>
                <div class="col-lg-9">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.titulo" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Imagen</label>
                <div class="col-lg-9">
                    <div *ngIf="!dataitem.imagen && !dataitem.img" class="cursor text-center m-t-10" (click)="SelecImagen()">
                        <img src="assets/subir-imagen.png" style="height:80px">
                    </div>
                    <div *ngIf="dataitem.imagen && !dataitem.img">
                        <a class="img-preview-container cursor" title="Imagen categoría">
                            <img [src]="dataitem.imagen" style="max-height:125px;">
                        </a>
                        <div class="text-center">
                            <a class="btn btn-sm btn-default" (click)="SelecImagen()">Cambiar imagen</a>
                            <a class="btn btn-sm btn-default m-l-10" (click)="DelImagen()"><i class="fa fa-trash"></i></a>
                        </div>
                    </div>
                    <div *ngIf="dataitem.img" class="img-preview-container cursor" (click)="SelecImagen()">
                        <img [src]="dataitem.img" style="max-height:125px;">
                    </div>    
                    <div class="form-group">
                      <label>Desde url externa</label>
                      <input id="txturlexterna" type="url" class="form-control" [(ngModel)]="dataitem.img_externa" maxlength="400" placeholder="Pega aquí la url o la imagen">
                      <img id="img-externa" src="" alt="" class="hidden">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Descripción <span class="text-danger">*</span></label>
                <div class="col-lg-9">
                    <textarea type="text" class="form-control" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="dataitem.descripcion" maxlength="5000" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-3 col-form-label">Precio <span class="text-danger">*</span></label>
                <div class="col-lg-5">
                    <input type="number" class="form-control" [(ngModel)]="dataitem.precio" maxlength="20">
                </div>
                <div class="col-lg-4">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.precio_sufijo" maxlength="50" placeholder="/ kilo">
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarModal" type="button" class="btn btn-primary" (click)="GuardarModal()">Guardar</button>
        </div>
        </div>
    </div>
</div>