import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html'
})
export class MiPerfilComponent implements OnInit {

  public data:any = {
    id: '',
    id_rol: '2',
    imagen: '',
    img: '',
    referencia: '00000',
    alias: '',
    nombre: '',
    apellidos: '',
    direccion: '',
    localidad: '',
    telefono: '',
    email: '',
    clave: ''
  };
  public imgsubir:string = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.data = this.globals.me;
    this.globals.Api('/me').subscribe(data => {
      this.globals.me = data;
      this.data = this.globals.me;
    });
  }
  
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen del usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        this.globals.me.imagen = '';
        return;
      }
      this.globals.Api('/borrar-imagen-usuario', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.globals.me.imagen = '';
        this.appref.tick();
      });
    });
  }

  EditarClave() {
    this.data.title = 'Editar contraseña usuario';
    this.data.clave = '';
    $('#modal-editar-clave-usuario').modal('show');
  }
  GuardarClaveUsuario() {
    if (
      this.data.clave == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    $.buttonloading('btnGuardarClaveUsuarioModal', 'Enviando');
    this.globals.Api('/editar-clave-usuario', this.data).subscribe(data => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#modal-editar-clave-usuario').modal('hide');
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  Guardar() {
    if (
      this.data.nombre == ''
      || this.data.apellidos == ''
      || this.data.email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;
    
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/usuarios-edit', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        if (data.value == 'Email repetido') {
          swal('Email existente', 'Existe un usuario con este email', 'error');return;
        }
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

}
