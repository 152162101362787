import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let Litepicker: any;

@Component({
  selector: 'app-reservas-restaurante',
  templateUrl: './reservas-restaurante.component.html'
})
export class ReservasRestauranteComponent implements OnInit {
  public reservas:any = [];
  public cargando:boolean = false;
  public params:any = {
    filtro: null
  };
  public dataitem:any = {};
  public espacios:any = [];
  public fechas_resaltadas:any = [];
  public tipovista:string = '';
  public espacio:any = null;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $(document).ready(() => {
      globalThis.calendar = new Litepicker({
        element: document.getElementById('calendario'),
        plugins: ['mobilefriendly'],
        inlineMode: true,
        singleMode: true,
        lang: 'es-ES',
        startDate: (this.globals.passData && this.globals.passData.fecha ? new Date(this.globals.passData.fecha) : new Date())
      });
      globalThis.calendar.on('change:month', (date, calendarIdx) => {
        let fecha = moment(date.dateInstance).format('YYYY-MM-DD');
        this.CargarFechasResaltadas(fecha);
      });
      globalThis.calendar.on('selected', (date1, date2) => {
        this.tipovista = '';
        this.espacio = '';
        this.params.fecha = moment(date1.dateInstance).format('YYYY-MM-DD');
        this.CargarGrid();
        this.appref.tick();
      });
      if (window.location.search.indexOf('add') != -1) {
        this.Nuevo();
      }
    });
    this.globals.Api('/mod-reservas-restaurante-espacios', this.params).subscribe(data => {
      if (!data || !data.length) return;
      this.espacios = data;
    });
    globalThis.esHoy = (fecha:any) => {return (moment().format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY'));};
    globalThis.esManana = (fecha:any) => {return (moment().add(1, 'days').format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY'));};
    this.params.fecha = moment(new Date()).format('YYYY-MM-DD');
    if (this.globals.passData && this.globals.passData.fecha) this.params.fecha = this.globals.passData.fecha;
    this.CargarGrid();
    this.CargarFechasResaltadas();
    this.globals.passData = {};
    setTimeout(() => {
      clearInterval($.IDINTERVReservas);
      $.IDINTERVReservas = setInterval(() => { this.Refrescar(); }, 10000);
    }, 200);
  }
  
  Refrescar() {
    this.CargarGrid();
    this.CargarFechasResaltadas();
  }
  CargarFechasResaltadas(date:any = null) {
    if (!date) date = this.params.fecha;
    this.fechas_resaltadas = [];
    this.globals.Api('/mod-reservas-restaurante-count', {mes: moment(date).format('M'), anio: moment(date).format('YYYY')}).subscribe(data => {
      if (!data || !data.length) return;
      for (let item of data) this.fechas_resaltadas.push(item.fecha_format);
      globalThis.calendar.setOptions({highlightedDays: this.fechas_resaltadas, startDate: moment(date)});
      globalThis.calendar.render();
    });
  }
  CargarGrid() {
    this.params.fecha_format = moment(this.params.fecha).locale('es').format('dddd D MMMM');
    if (globalThis.esHoy(this.params.fecha)) this.params.fecha_format = 'HOY';
    if (globalThis.esManana(this.params.fecha)) this.params.fecha_format = 'MAÑANA';
    this.globals.Api('/mod-reservas-restaurante', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.reservas = [];
        globalThis.original_reservas = this.reservas;
        return;
      }
      this.reservas = data;
      globalThis.original_reservas = this.reservas;
    });
  }
  TotalPersonas() {
    if (!this.reservas || !this.reservas.length) return;
    let total = 0;
    for (let item of this.reservas) total += item.personas;
    return total;
  }
  TotalPersonasTurno() {
    if (!this.reservas || !this.reservas.length) return 0;
    if (!this.tipovista || this.tipovista == '') return;
    let total = 0;
    for (let item of this.reservas.filter(el => el.turno == this.tipovista)) total += item.personas;
    return total;
  }
  TotalPersonasEspacio(itemespacio:any) {
    if (!globalThis.original_reservas || !globalThis.original_reservas.length) return;
    let total = 0;
    if (this.tipovista && this.tipovista != '') {
      for (let item of globalThis.original_reservas.filter(el => el.espacio == itemespacio.espacio && el.turno == this.tipovista)) total += item.personas;
    } else {
      for (let item of globalThis.original_reservas.filter(el => el.espacio == itemespacio.espacio)) total += item.personas;
    }
    return total;
  }
  TotalComidas() {
    return this.reservas.filter(el => el.turno == 'Comida').length;
  }
  TotalCenas() {
    return this.reservas.filter(el => el.turno == 'Cena').length;
  }
  SetEspacio(item:any) {
    this.espacio = item;
    if (!this.espacio) this.reservas = globalThis.original_reservas;
    else {
      this.reservas = globalThis.original_reservas.filter(el => el.espacio == this.espacio.espacio);
    }
  }
  Buscar() {
    this.globals.Api('/mod-reservas-restaurante-espacios', this.params).subscribe(data => {
      if (!data || !data.length) return;
      this.espacios = data;
      this.CargarFechasResaltadas();
    });
    globalThis.esHoy = (fecha:any) => {return (moment().format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY'));};
    globalThis.esManana = (fecha:any) => {return (moment().add(1, 'days').format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY'));};
    this.params.fecha = moment(new Date()).format('YYYY-MM-DD');
    this.CargarGrid();
    this.CargarFechasResaltadas();
  }
  Nuevo() {
    $('#modal-crear-reserva').modal('show');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    if (globalThis.calendar.options.startDate) this.dataitem.alta = moment(globalThis.calendar.options.startDate.dateInstance).format('YYYY-MM-DD');
    this.dataitem.personas = 1;
    if (this.espacios && this.espacios.length) this.dataitem.espacio = this.espacios[0].espacio;
    if (this.espacio) this.dataitem.espacio = this.espacio;
    setTimeout(() => {
      $('#modal-crear-reserva input[type="text"]')[0].focus();
    }, 500);
  }
  Editar(item:any) {
    this.dataitem = JSON.parse(JSON.stringify(item));
    this.dataitem.alta = moment(this.dataitem.alta).format('YYYY-MM-DD');
    $('#modal-crear-reserva').modal('show');
    setTimeout(() => {
      $('#modal-crear-reserva input[type="text"]')[0].focus();
    }, 500);
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar reserva?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/mod-reservas-restaurante-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-reserva').modal('hide');
        this.reservas.splice(this.reservas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  EnviarEmail(item:any, event:any) {
    event.stopPropagation();
    swal({
      title: "¿Enviar un correo de confirmación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/mod-reservas-restaurante-send-email', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success('Email enviado correctamente', 'Email enviado');
      });
    });
  }
  EnviarWhatsapp(item:any, event:any) {
    event.stopPropagation();
    if (!item.telefono || item.telefono == '') {
      toastr.info('No se ha guardado número de teléfono', 'Sin número');
      return;
    }
    let telefono = item.telefono.toString().trim();
    telefono = telefono.replace(/ /g, "");
    if (telefono.indexOf('+') == -1) telefono = '+34'+telefono;
    let fecha = moment(item.alta).locale('es').format('dddd D MMMM');
    let enlace = document.createElement('a');
    let cliente = (this.globals.cliente ? this.globals.cliente.empresa : this.globals.me.cliente);
    let texto = `¡Buenas!%0aHemos reservado tu mesa${item.espacio && item.espacio != '' ? ` en *${item.espacio}*` : ''} de ${item.personas} personas para el *${fecha}* a las *${item.hora}h* en *${cliente}*.`;
    texto += `%0aSi no pudieras asistir, por favor avísanos con tiempo para cancelar.`;
    if (this.globals.cliente) {
      if (this.globals.cliente.config.url_carta) {
        texto += `%0aMientras tanto puedes ir viendo nuestra carta aquí: ${this.globals.cliente.config.url_carta}.`;
      }
    } else {
      if (this.globals.me.url_carta) {
        texto += `%0aMientras tanto puedes ir viendo nuestra carta aquí: ${this.globals.me.url_carta}.`;
      }
    }
    texto += `%0a%0a¡Muchas Gracias!`;
    enlace.href = `https://api.whatsapp.com/send?phone=${telefono}&text=${texto}`;
    enlace.target = '_blank';
    enlace.click();
  }
  
  Menos(tipo:string) {
    if (tipo == 'personas') {
      if (this.dataitem.personas > 1) this.dataitem.personas--;
    }
  }
  Mas(tipo:string) {
    if (tipo == 'personas') {
      this.dataitem.personas++;
    }
  }
  GuardarModal() {
    if (
        !this.dataitem.nombre
      || !this.dataitem.alta
      || this.dataitem.nombre == ''
      || this.dataitem.alta == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    $.buttonloading('btnGuardarModal', 'Enviando');
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/mod-reservas-restaurante-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-reserva').modal('hide');
        this.CargarGrid();
        this.CargarFechasResaltadas();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    } else {
      this.globals.Api('/mod-reservas-restaurante-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-reserva').modal('hide');
        this.CargarGrid();
        this.CargarFechasResaltadas();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    }
  }

}
