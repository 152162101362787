import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let L: any;

@Component({
  selector: 'app-organizadores',
  templateUrl: './organizadores.component.html'
})
export class OrganizadoresComponent implements OnInit {
  public title:string = 'Organizadores';
  public data:any = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null
  };

  public dataitem:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/evt-organizadores', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/evt-organizadores/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    $('#modal-crear-organizador').modal('show');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    setTimeout(() => {
      $('#modal-crear-organizador input[type="text"]')[0].focus();
    }, 500);
    $('#modal-crear-organizador input[type="text"]').last()[0].onkeyup = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.GuardarModal();
      }
    };
  }
  Editar(item:any) {
    this.dataitem = JSON.parse(JSON.stringify(item));
    $('#modal-crear-organizador').modal('show');
    setTimeout(() => {
      $('#modal-crear-organizador input[type="text"]')[0].focus();
    }, 500);
    $('#modal-crear-organizador input[type="text"]').last()[0].onkeyup = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.GuardarModal();
      }
    };
  }
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.dataitem.img = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  DelImagen() {
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.dataitem.id || this.dataitem.id == '') {
        this.dataitem.img = '';
        this.dataitem.imagen = '';
        return;
      }
      this.globals.Api('/evt-organizadores-imagen-del', {id: this.dataitem.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.dataitem.img = '';
        this.dataitem.imagen = '';
        this.appref.tick();
      });
    });
  }
  GuardarModal() {
    if (
        !this.dataitem.nombre
      || this.dataitem.nombre == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    $.buttonloading('btnGuardarModal', 'Enviando');
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/evt-organizadores-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-organizador').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    } else {
      this.globals.Api('/evt-organizadores-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-organizador').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    }
  }
  Borrar(item:any) {
    $('.sweet-custom-message').remove();
    let that = this;
    swal({
      title: "¿Borrar organizador?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {
        that.globals.Api('/evt-organizadores-del', {id: item.id, id_organizador_nuevo: $('#cmb-asignar').val()}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          that.data.splice(that.data.findIndex(el => el.id == item.id), 1);
          that.appref.tick();
        });
      }
      $('.sweet-custom-message').remove();
    });
    let options = '';
    for (let el of this.data) {
      if (el.id == item.id) continue;
      options += `<option value="${el.id}">${el.nombre}</option>`;
    }
    setTimeout(() => {
      $('.sweet-alert h2').after(`
        <div class="sweet-custom-message">
          Asignar eventos de '${item.nombre}' a:<br>
          <select id="cmb-asignar" class="form-control">${options}</select>
        </div>
      `);
    }, 300);
  }

}
