<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Eventos</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarCalendario()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Eventos</li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo evento</a>
                        </div>
                    </div>
                </div>
                
                
                <div class="row">
                    <div class="col-lg-4 col-md-12 col-xs-12">
                        <div class="block full">
                            <div class="block-title">
                                <h2>Eventos</h2>
                                <button class="btn btn-default middle m-r-10 pull-right" (click)="MostrarOcultarPermanentes()" *ngIf="separar_eventos">
                                    <span *ngIf="!eventos_permanentes">Mostrar permanentes</span>
                                    <span *ngIf="eventos_permanentes">Mostrar de corta duración</span>
                                </button>
                            </div>
                            <div class="table-responsive table-grid block full break-padding table-listado-eventos">
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                          <th style="width:50px"></th>
                                          <th order="titulo">Título</th>
                                          <th style="width:50px" order="fecha_inicio">Fecha</th>
                                          <th style="width:50px">Editar</th>
                                          <th style="width:50px">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of GetEventosFiltrados()" class="cursor">
                                          <td class="text-center" (click)="Editar(item)">
                                            <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado">
                                            <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i>
                                          </td>
                                          <td (click)="Editar(item)">
                                            <div class="font-bold font-13 text-primary" style="line-height:1.1">{{item.titulo}}</div>
                                            <div>{{item.categoria}}</div>
                                            <!-- <ng-container *ngIf="globals.me && globals.me.tarifa && globals.me.tarifa == 'spg-event-planner-advanced'">
                                                <div>
                                                    <a class="btn btn-default btn-xs" (click)="ExportarHTMLCompleto($event, item)"><i class="fa fa-code"></i> Comp.</a>
                                                    <a class="btn btn-default btn-xs m-l-10" (click)="ExportarHTMLSimple($event, item)"><i class="fa fa-code"></i> Simp.</a>
                                                </div>
                                            </ng-container> -->
                                          </td>
                                          <td>
                                              <div class="font-11">
                                                {{item.fecha_inicio_format}}
                                                <span *ngIf="item.fecha_fin_format && item.fecha_inicio_format != item.fecha_fin_format">- {{item.fecha_fin_format}}</span>
                                              </div>
                                          </td>
                                          <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                          <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ng-container *ngIf="globals.me && globals.me.tarifa && globals.me.tarifa == 'spg-event-planner-advanced'">
                              <br>
                              <div class="block m-b-20" style="padding-bottom:20px">
                                <div class="block-title"><h4 style="font-weight:normal;font-size:14px">Exportar html para correo</h4></div>
                                <a class="btn btn-default btn-sm m-t-10" (click)="globals.ExportarHTMLCabecera()"><i class="fa fa-code"></i> Exportar cabecera email</a><br>
                                <a class="btn btn-default btn-sm m-t-10" (click)="globals.ExportarHTMLPie()"><i class="fa fa-code"></i> Exportar pie email</a><br>
                              </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-xs-12 noselect">
                        <div class="block full calendar-container">
                            <div class="block-title">
                                <h2>Calendario</h2>
                                <div class="badges">
                                    <span class="badge bg-default" (click)="FiltrarCategoria()">Todas</span>
                                    <span *ngFor="let item of categorias" class="badge bg-default" [style.background-color]="item.color" (click)="FiltrarCategoria(item.id)">{{item.categoria}}</span>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div id="calendar"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>