import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let L: any;

@Component({
  selector: 'app-sugerencias',
  templateUrl: './sugerencias.component.html',
  styles: [
  ]
})
export class SugerenciasComponent implements OnInit {
  public title:string = 'Sugerencias';
  public data:any = [];
  public params:any = {
    filtro: null
  };
  public dataitem:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.CargarGrid();
    $('#sugerencias-list').sortable({
      stop: (event, ui) => {
        let arr = [];
        $('#sugerencias-list .col').each(function() {
          let id = $(this).attr('itemid');
          if (!id || id == '') return;
          arr.push(id);
        });
        if (!arr || !arr.length) return;
        this.globals.Api('/mod-sugerencias-order', {ordenid: arr}).subscribe(data => {
          toastr.success(`Orden cambiado correctamente`, 'Orden cambiado');
        });
      }
    });
  }
  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/mod-sugerencias', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      for (let item of data) item.descripcion = item.descripcion.replace(/(?:\r\n|\r|\n)/g, '<br>');
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
    });
  }
  Buscar() {
    this.CargarGrid();
  }
  Nuevo() {
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    $('#modal-crear-sugerencia').modal('show');
    $('#modal-crear-sugerencia .modal-title').html('Nueva sugerencia');
    setTimeout(() => {
      $('#modal-crear-sugerencia input[type="text"]')[0].focus();
    }, 500);
    let that = this;
    if ($('#txturlexterna').length) {
      $('#txturlexterna')[0].onpaste = function (e) {
        const dT = e.clipboardData || e.originalEvent.clipboardData;
        const html = dT.getData('text/html') || "";
        const parsed = new DOMParser().parseFromString(html, 'text/html');
        const img = parsed.querySelector('img');
        if( !img ) {
          setTimeout(() => {
            that.dataitem.imagen = $('#txturlexterna').val();
            that.appref.tick();
          }, 50);
        } else {
          const url = img.src;
          that.dataitem.imagen = url;
          that.dataitem.img_externa = url;
          that.appref.tick();
        }
      };
    }
  }
  Editar(item:any) {
    this.dataitem = JSON.parse(JSON.stringify(item));
    this.dataitem.descripcion = item.descripcion.replace(/(?:<br>)/g, '\n');
    $('#modal-crear-sugerencia').modal('show');
    $('#modal-crear-sugerencia .modal-title').html(this.dataitem.titulo);
    setTimeout(() => {
      $('#modal-crear-sugerencia input[type="text"]')[0].focus();
    }, 500);
    let that = this;
    if ($('#txturlexterna').length) {
      $('#txturlexterna')[0].onpaste = function (e) {
        const dT = e.clipboardData || e.originalEvent.clipboardData;
        const html = dT.getData('text/html') || "";
        const parsed = new DOMParser().parseFromString(html, 'text/html');
        const img = parsed.querySelector('img');
        if( !img ) {
          setTimeout(() => {
            that.dataitem.imagen = $('#txturlexterna').val();
            that.appref.tick();
          }, 50);
        } else {
          const url = img.src;
          that.dataitem.imagen = url;
          that.dataitem.img_externa = url;
          that.appref.tick();
        }
      };
    }
  }
  Borrar(item:any, event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar sugerencia?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/mod-sugerencias-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.dataitem.img = base64;
        this.appref.tick();
      }, 1024);
    });
    input.click();
  }
  DelImagen() {
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.dataitem.id || this.dataitem.id == '') {
        this.dataitem.img = '';
        this.dataitem.imagen = '';
        return;
      }
      this.globals.Api('/mod-sugerencias-imagen-del', {id: this.dataitem.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.dataitem.img = '';
        this.dataitem.imagen = '';
        this.appref.tick();
      });
    });
  }
  GuardarModal() {
    if (
         !this.dataitem.titulo
      || !this.dataitem.descripcion
      || !this.dataitem.precio
      || this.dataitem.titulo == ''
      || this.dataitem.descripcion == ''
      || this.dataitem.precio == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    $.buttonloading('btnGuardarModal', 'Enviando');
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/mod-sugerencias-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-sugerencia').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    } else {
      this.globals.Api('/mod-sugerencias-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-sugerencia').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    }
  }

}
