import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ace: any;
declare let tinymce: any;

@Component({
  selector: 'app-edit-cliente-spa',
  templateUrl: './edit-cliente-spa.component.html'
})
export class EditClienteSpaComponent implements OnInit {
  public title:string = 'Nuevo cliente';
  public data:any = {
    config: {separar_eventos_permanentes: 1},
  };
  public imgsubir:string = '';
  public tarifas = [];
  public dataitem:any = {};
  public iframe:any = '';
  public wp_tablas = [];

  constructor(
    public sanitizer: DomSanitizer,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo cliente';
    } else {
      if (this.data.empresa && this.data.empresa != '') {
        this.title = this.data.empresa;
      } else {
        this.title = this.data.nombre + (this.data.apellidos ? ' ' + this.data.apellidos : '');
      }
      this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://spgevent.pecesgordos.es/login?cliente=' + this.data.id
      );
    }
    this.globals.Api('/tarifas').subscribe(data => {
      if (!data || data.length == 0) return;
      this.tarifas = data;
      if (!this.data.id_tarifa && this.tarifas.length) this.data.id_tarifa = this.tarifas[0].id;
    });
    $(document).ready(() => {
      $('.url-paste').on('paste', (e:any) => {
        const dT = e.clipboardData || e.originalEvent.clipboardData;
        const html = dT.getData('text/html') || "";
        const parsed = new DOMParser().parseFromString(html, 'text/html');
        const img = parsed.querySelector('img');
        if( !img ) {
        } else {
          const url = img.src;
          e.target.value = url;
        }
        this.appref.tick();
      });
      setTimeout(() => {
        $.csseditor = ace.edit("txtcustomcss");
        $.csseditor.setTheme("ace/theme/monokai");
        $.csseditor.session.setMode("ace/mode/css");
      }, 500);
    });
  }

  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    }, 800);
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen del cliente?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/clientes-imagen-del', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }

  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.data.provincia = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad;
      this.data.provincia = data.provincia;
      this.appref.tick();
    });
  }
  GetCheckedModulo(slug:string = '') {
    return (this.data && this.data.modulos && this.data.modulos.find(el => el.slug == slug));
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/clientes-spa')).then();
  }
  Guardar() {
    if (
      !this.data.empresa
      || !this.data.nombre
      || !this.data.apellidos
      || !this.data.email
      || this.data.empresa == ''
      || this.data.nombre == ''
      || this.data.apellidos == ''
      || this.data.email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    if ($.csseditor && this.data.config) {
      this.data.config.customcss = $.csseditor.getValue();
      this.data.config.customcss = this.data.config.customcss.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]*>?/gm, '');
    }

    this.data.modulos = [];
    let checksmodulos:any = document.querySelectorAll('.chk-modulo');
    for (let item of checksmodulos) {
      if (!item.checked) continue;
      this.data.modulos.push({
        slug: $(item).attr('slug'),
        icono: $(item).attr('icono'),
        modulo: $(item).attr('nombre')
      });
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/clientes-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/clientes-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

  CambiarAcceso(item:any) {
    this.globals.Api('/editar-acceso-usuario', {id: item.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      item.acceso = data.value;
      this.appref.tick();
    });
  }
  EditarUsuario(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios/edit')).then();
  }
  BorrarUsuario(item:any) {
    swal({
      title: "¿Borrar usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/usuarios-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.usuarios.splice(this.data.usuarios.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  EmailTest() {
    this.globals.Api('/email-test', {id_cliente: this.data.id}).subscribe(data => {
      swal('Enviado', 'Intento de email enviado al servidor', 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }
  EmailTestWhatsApp() {
    this.globals.Api('/whatsapp-test', {id_cliente: this.data.id}).subscribe(data => {
      if (!data) {
        swal('Error', 'Se ha producido un error', 'error');
        return;
      }
      swal('Enviado', data, 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }
  VistaCliente() {
    this.globals.cliente = JSON.parse(JSON.stringify(this.data));
    this.globals.SaveData(this.globals.cliente, 'cliente-activo');
    localStorage.removeItem('config');
    this.globals.Api('/configuracion-public', {id_cliente: (this.globals.cliente ? this.globals.cliente.id: null)}).subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      $.CargarConfiguracionGlobal();
    });
    this.ngZone.run(() => this.router.navigateByUrl('/dashboard')).then();
  }
  Portapapeles() {
    this.globals.CopyClipboard(this.data.id);
    toastr.info(`ID Cliente: ${this.data.id}`,'Copiado al portapapeles');
  }
  GenerarWPCustomPosts() {
    $.buttonloading('btn-regenerar-custom-posts', 'Generando');
    this.globals.Api('/pub-generar-eventos-wp', {id_cliente: this.data.id}).subscribe((data:any) => {
      $.buttoncancelloading('btn-regenerar-custom-posts');
      if (!data || data.error) {toastr.error('Se ha producido un error', 'Error');return;}
      toastr.success('Eventos generados correctamente en WordPress', 'Generados');
    }, error => {
      $.buttoncancelloading('btn-regenerar-custom-posts');
    });
  }
  TestWPConexion() {
    this.globals.Api('/wordpress-test', {id_cliente: this.data.id}).subscribe((data:any) => {
      if (!data || !data.length) {toastr.error('Error en la conexión al WordPress', 'Error');return;}
      this.wp_tablas = data;
      toastr.success('Conexión correcta a WordPress', 'Conectado');
    })
  }
}
