import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let Litepicker: any;
declare let toastr: any;

@Component({
  selector: 'app-backend-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  public data:any = {};
  public anio:any = new Date().getFullYear();
  public fechas_resaltadas:any = [];
  public reservas:any = [];
  public texto_aviso:string = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.ComprobarLicencia();
    if ([0, 1, 2, 4].includes(this.globals.me.id_rol)) {
      let params:any = {};
      if ([0].includes(this.globals.me.id_rol) && !this.globals.cliente) params.admin = true;
      this.globals.Api('/datos-generales', params).subscribe(data => {
        if (!data) return;
        this.data = data;
        if (this.globals.GetModulo('reservas-restaurante')) {
          this.CargarGraficaReservas();
          this.CargarReservas();
        }
        this.CargarGraficaEventos();
        this.CargarGraficaVisitas();
      });
      setTimeout(() => {
        if (this.globals.GetModulo('reservas-restaurante')) {
          globalThis.calendar = new Litepicker({
            element: document.getElementById('calendario-reservas'),
            plugins: ['mobilefriendly'],
            inlineMode: true,
            singleMode: true,
            lang: 'es-ES',
            startDate: new Date()
          });
          globalThis.calendar.on('change:month', (date, calendarIdx) => {
            let fecha = moment(date.dateInstance).format('YYYY-MM-DD');
            this.CargarFechasResaltadas(fecha);
          });
          globalThis.calendar.on('selected', (date1, date2) => {
            this.globals.passData = {};
            this.globals.passData.fecha = moment(date1.dateInstance).format('YYYY-MM-DD');
            this.ngZone.run(() => this.router.navigateByUrl('/reservas-restaurante')).then();
          });
          this.CargarFechasResaltadas();
        }
      }, 500);
    }
    if (this.globals.config) this.texto_aviso = this.globals.config.texto_aviso;
  }
  CargarGraficaReservas() {
    if ([0].includes(this.globals.me.id_rol) && !this.globals.cliente) return;
    let chartClassicDash    = $('#chart-reservas');

    // Data for the chart
    var dataReservas        = [
      [1, this.data.total_reservas_ene], [2, this.data.total_reservas_feb], [3, this.data.total_reservas_mar],
      [4, this.data.total_reservas_abr], [5, this.data.total_reservas_may], [6, this.data.total_reservas_jun],
      [7, this.data.total_reservas_jul], [8, this.data.total_reservas_ago], [9, this.data.total_reservas_sep],
      [10, this.data.total_reservas_oct], [11, this.data.total_reservas_nov], [12, this.data.total_reservas_dic]
    ];
    var dataPersonas        = [
      [1, this.data.total_reservas_personas_ene], [2, this.data.total_reservas_personas_feb], [3, this.data.total_reservas_personas_mar],
      [4, this.data.total_reservas_personas_abr], [5, this.data.total_reservas_personas_may], [6, this.data.total_reservas_personas_jun],
      [7, this.data.total_reservas_personas_jul], [8, this.data.total_reservas_personas_ago], [9, this.data.total_reservas_personas_sep],
      [10, this.data.total_reservas_personas_oct], [11, this.data.total_reservas_personas_nov], [12, this.data.total_reservas_personas_dic]
    ];

    var dataMonths          = [[1, 'Ene'], [2, 'Feb'], [3, 'Mar'], [4, 'Abr'], [5, 'May'], [6, 'Jun'], [7, 'Jul'], [8, 'Ago'], [9, 'Sep'], [10, 'Oct'], [11, 'Nov'], [12, 'Dic']];

    let color_primary = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
    let color_secondary = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary');

    // Classic Chart
    $.plot(chartClassicDash,
        [
          {
              label: 'Personas',
              data: dataPersonas,
              lines: {show: true, fill: true, fillColor: {colors: [{opacity: .6}, {opacity: .6}]}},
              points: {show: true, radius: 5}
          },
          {
              label: 'Reservas',
              data: dataReservas,
              lines: {show: true, fill: true, fillColor: {colors: [{opacity: .6}, {opacity: .6}]}},
              points: {show: true, radius: 5}
          }
        ],
        {
          colors: [color_primary, color_secondary],
          legend: {show: true, position: 'nw', backgroundOpacity: 0},
          grid: {borderWidth: 0, hoverable: true, clickable: true},
          yaxis: {show: false, tickColor: '#f5f5f5', ticks: 3},
          xaxis: {ticks: dataMonths, tickColor: '#f9f9f9'}
        }
    );

    // Creating and attaching a tooltip to the classic chart
    var previousPoint = null, ttlabel = null;
    chartClassicDash.bind('plothover', function(event, pos, item) {
        if (item) {
            if (previousPoint !== item.dataIndex) {
                previousPoint = item.dataIndex;

                $('#chart-tooltip').remove();
                var x = item.datapoint[0], y = item.datapoint[1];

                if (item.seriesIndex === 0) {
                  ttlabel = '<strong>' + y + '</strong> personas';
                }
                if (item.seriesIndex === 1) {
                    ttlabel = '<strong>' + y + '</strong> reservas';
                }

                $('<div id="chart-tooltip" class="chart-tooltip">' + ttlabel + '</div>')
                    .css({top: item.pageY - 45, left: item.pageX + 5}).appendTo("body").show();
            }
        }
        else {
            $('#chart-tooltip').remove();
            previousPoint = null;
        }
    });
  }
  CargarGraficaEventos() {
    if ([0].includes(this.globals.me.id_rol) && !this.globals.cliente) return;
    let chartClassicDash    = $('#chart-eventos');

    // Data for the chart
    var dataEventos        = [
      [1, this.data.total_eventos_ene], [2, this.data.total_eventos_feb], [3, this.data.total_eventos_mar],
      [4, this.data.total_eventos_abr], [5, this.data.total_eventos_may], [6, this.data.total_eventos_jun],
      [7, this.data.total_eventos_jul], [8, this.data.total_eventos_ago], [9, this.data.total_eventos_sep],
      [10, this.data.total_eventos_oct], [11, this.data.total_eventos_nov], [12, this.data.total_eventos_dic]
    ];

    var dataMonths          = [[1, 'Ene'], [2, 'Feb'], [3, 'Mar'], [4, 'Abr'], [5, 'May'], [6, 'Jun'], [7, 'Jul'], [8, 'Ago'], [9, 'Sep'], [10, 'Oct'], [11, 'Nov'], [12, 'Dic']];

    let color_primary = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
    let color_secondary = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary');

    // Classic Chart
    $.plot(chartClassicDash,
        [
            {
                label: 'Eventos',
                data: dataEventos,
                lines: {show: true, fill: true, fillColor: {colors: [{opacity: .6}, {opacity: .6}]}},
                points: {show: true, radius: 5}
            }
        ],
        {
            colors: [color_primary],
            legend: {show: true, position: 'nw', backgroundOpacity: 0},
            grid: {borderWidth: 0, hoverable: true, clickable: true},
            yaxis: {show: false, tickColor: '#f5f5f5', ticks: 3},
            xaxis: {ticks: dataMonths, tickColor: '#f9f9f9'}
        }
    );

    // Creating and attaching a tooltip to the classic chart
    var previousPoint = null, ttlabel = null;
    chartClassicDash.bind('plothover', function(event, pos, item) {

        if (item) {
            if (previousPoint !== item.dataIndex) {
                previousPoint = item.dataIndex;

                $('#chart-tooltip').remove();
                var x = item.datapoint[0], y = item.datapoint[1];

                if (item.seriesIndex === 0) {
                    ttlabel = '<strong>' + y + '</strong> eventos';
                }

                $('<div id="chart-tooltip" class="chart-tooltip">' + ttlabel + '</div>')
                    .css({top: item.pageY - 45, left: item.pageX + 5}).appendTo("body").show();
            }
        }
        else {
            $('#chart-tooltip').remove();
            previousPoint = null;
        }
    });
  }
  CargarGraficaVisitas() {
    if ([0].includes(this.globals.me.id_rol) && !this.globals.cliente) return;
    let chart    = $('#chart-visitas');
    if (!chart || !chart.length) return;
    if (!this.data.eventos_mas_visitados || !this.data.eventos_mas_visitados.length) return;

    var data = [
      [1, this.data.eventos_mas_visitados[0].visitas], [2, this.data.eventos_mas_visitados[1].visitas], [3, this.data.eventos_mas_visitados[2].visitas],
      [4, this.data.eventos_mas_visitados[3].visitas], [5, this.data.eventos_mas_visitados[4].visitas]
    ];
    var dataLabels = [[1, '#1'], [2, '#2'], [3, '#3'], [4, '#4'], [5, '#5']];

    let color_primary = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
    let color_secondary = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary');

    // Classic Chart
    $.plot(chart,
      [
        {
            label: 'Visitas',
            data: data
        }
      ],
      {
        series: {
          bars: {
              show: true,
              barWidth: .9,
              lineWidth: 0,
              order: 1,
              fillColor: {
                  colors: [{
                      opacity: 1
                  }, {
                      opacity: 1
                  }]
              }
          }
        },
        colors: [color_primary, color_secondary],
        legend: {show: false, position: 'nw', backgroundOpacity: 0},
        grid: {borderWidth: 0, hoverable: true, clickable: true},
        yaxis: {show: false, tickColor: '#f5f5f5', ticks: 3},
        xaxis: {ticks: dataLabels, tickColor: '#f9f9f9', alignTicksWithAxis: 2}
      }
    );

    // Creating and attaching a tooltip to the classic chart
    let that = this;
    var previousPoint = null, ttlabel = null;
    chart.bind('plothover', function(event, pos, item) {
        if (item) {
            if (previousPoint !== item.dataIndex) {
                previousPoint = item.dataIndex;

                $('#chart-tooltip').remove();
                var x = item.datapoint[0], y = item.datapoint[1];

                if (item.seriesIndex === 0) {
                  let datos = that.data.eventos_mas_visitados[item.dataIndex];
                  ttlabel = '<strong>' + datos.titulo + '</strong><br>';
                  ttlabel += '<strong>' + y + '</strong> visitas';
                }

                $('<div id="chart-tooltip" class="chart-tooltip">' + ttlabel + '</div>')
                    .css({top: item.pageY - 45, left: item.pageX + 5}).appendTo("body").show();
            }
        }
        else {
            $('#chart-tooltip').remove();
            previousPoint = null;
        }
    });
  }
  CargarFechasResaltadas(date:any = null) {
    if (!date) date = new Date();
    this.fechas_resaltadas = [];
    this.globals.Api('/mod-reservas-restaurante-count', {mes: moment(date).format('M'), anio: moment(date).format('YYYY')}).subscribe(data => {
      if (!data || !data.length) return;
      for (let item of data) this.fechas_resaltadas.push(item.fecha_format);
      globalThis.calendar.setOptions({highlightedDays: this.fechas_resaltadas, startDate: moment(date)});
      globalThis.calendar.render();
    });
  }
  CargarReservas() {
    let params:any = {};
    params.fecha = moment().format('YYYY-MM-DD');
    this.globals.Api('/mod-reservas-restaurante', params).subscribe(data => {
      if (!data || !data.length) return;
      this.reservas = data;
    });
  }
  GuardarAviso() {
    $.buttonloading('.btn-guardar-aviso', 'Enviando');
    this.globals.Api('/clientes-edit-texto-aviso', {id: this.globals.me.id_cliente, texto_aviso: this.texto_aviso}).subscribe(data => {
      $.buttoncancelloading('.btn-guardar-aviso');
      if (!data || data.error) {
        toastr.error('Se ha producido un error', 'Error');return;
      }
      toastr.success('Datos guardados correctamente', 'Guardado');
    }, error => {
      $.buttoncancelloading('.btn-guardar-aviso');
    });
  }
}
