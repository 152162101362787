<div id="sidebar"  *ngIf="globals && globals.me && globals.me.id_rol == 0">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <span class="sidebar-nav-mini-hide m-l-10">SPG<strong>Event</strong></span>
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <ng-container *ngIf="!globals.cliente">
                <ul class="sidebar-nav">
                    <li>
                        <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/config-app']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/clientes-spa']" routerLinkActive="active"><i class="icofont-envelope sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/usuarios']" routerLinkActive="active"><i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                    </li>
                </ul>
            </ng-container>

            <ul class="sidebar-nav" *ngIf="globals.cliente">
                <li class="bg-header p-l-10">
                    <a [routerLink]="['/dashboard']" style="line-height:1.1;padding:10px;padding-bottom:5px;margin-top:-10px;">
                        <span class="sidebar-nav-mini-hide">{{globals.cliente.empresa}}</span>
                    </a>
                    <div class="sub-tarifa sidebar-nav-mini-hide">{{globals.cliente.tarifa_nombre}}</div>
                </li>
                <ng-container *ngIf="globals.cliente.modulos">
                    <li *ngFor="let item of globals.cliente.modulos">
                        <a [routerLink]="['/'+item.slug]" routerLinkActive="active"><i class="{{item.icono}} sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">{{item.modulo}}</span></a>
                    </li>
                    <li *ngIf="globals.GetModulo('reservas-restaurante')">
                        <a [routerLink]="['/espacios-reservas']" routerLinkActive="active"><i class="icofont-site-map sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Espacios reservas</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="globals.cliente.modulos && globals.cliente.modulos.length">
                    <li>
                        <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlaeventos.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                            <i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Eventos</span></a>
                        <ul>
                            <li>
                                <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                            </li>
                            <li>
                                <a [routerLink]="['/eventos']" routerLinkActive="active" #rlaeventos="routerLinkActive">Todos los eventos</a>
                            </li>
                            <li>
                                <a [routerLink]="['/eventos/add']" routerLinkActive="active" #rlaeventos="routerLinkActive"><i class="fa fa-plus"></i> Nuevo evento</a>
                            </li>
                            <li>
                                <a [routerLink]="['/espacios']" routerLinkActive="active"><i class="icofont-presentation sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Espacios</span></a>
                            </li>
                            <li>
                                <a [routerLink]="['/categorias']" routerLinkActive="active"><i class="icofont-list sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Categorías</span></a>
                            </li>
                            <li>
                                <a [routerLink]="['/organizadores']" routerLinkActive="active"><i class="icofont-people sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Organizadores</span></a>
                            </li>
                            <li *ngIf="globals.cliente.tarifa == 'spg-event-planner-advanced'">
                                <a [routerLink]="['/suscriptores']" routerLinkActive="active"><i class="icofont-envelope sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Suscriptores</span></a>
                            </li>
                            <li *ngIf="globals.cliente.tarifa == 'spg-event-planner-advanced'">
                                <a [routerLink]="['/mensajes']" routerLinkActive="active"><i class="fa fa-whatsapp sidebar-nav-icon"></i>
                                    <span class="sidebar-nav-mini-hide">Mensajes</span>
                                    <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp">{{globals.cmensajeswhatsapp}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ng-container>
                <ng-container *ngIf="!globals.cliente.modulos || !globals.cliente.modulos.length">
                    <li>
                        <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/eventos']" routerLinkActive="active"><i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Eventos</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/espacios']" routerLinkActive="active"><i class="icofont-presentation sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Espacios</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/categorias']" routerLinkActive="active"><i class="icofont-list sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Categorías</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/organizadores']" routerLinkActive="active"><i class="icofont-people sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Organizadores</span></a>
                    </li>
                    <li *ngIf="globals.cliente.tarifa == 'spg-event-planner-advanced'">
                        <a [routerLink]="['/suscriptores']" routerLinkActive="active"><i class="icofont-envelope sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Suscriptores</span></a>
                    </li>
                    <li *ngIf="globals.cliente.tarifa == 'spg-event-planner-advanced'">
                        <a [routerLink]="['/mensajes']" routerLinkActive="active"><i class="fa fa-whatsapp sidebar-nav-icon"></i>
                            <span class="sidebar-nav-mini-hide">Mensajes</span>
                            <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp">{{globals.cmensajeswhatsapp}}</span>
                        </a>
                    </li>
                </ng-container>
                <li>
                    <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rladinamizadores.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                        <i class="icofont-people sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Dinamizadores</span></a>
                    <ul>
                        <li>
                            <a [routerLink]="['/dinamizadores']" routerLinkActive="active" #rladinamizadores="routerLinkActive">Todos los dinamizadores</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dinamizadores/add']" routerLinkActive="active" #rladinamizadores="routerLinkActive"><i class="fa fa-plus"></i> Nuevo dinamizador</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="['/usuarios']" routerLinkActive="active"><i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                </li>
            </ul>
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div class="copyright sidebar-nav-mini-hide">
        <div class="copy-title">SPG Event</div>
        <a class="copy-enlace" href="https://pecesgordos.es" target="_blank">© Peces Gordos Estudio</a>
    </div>
    <!-- END Sidebar Extra Info -->
</div>
<div id="sidebar"  *ngIf="globals && globals.me && globals.me.id_rol != 0">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background {{globals.config && globals.config.logo_menu ? 'sidebar-brand-cliente' : null}}">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <ng-container *ngIf="globals.config && globals.config.logo_menu">
                <img [src]="globals.config.logo_menu" alt="">
            </ng-container>
            <ng-container *ngIf="!globals.config || !globals.config.logo_menu">
                <span class="sidebar-nav-mini-hide m-l-10">SPG<strong>Event</strong></span>
            </ng-container>
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <!-- ADMIN -->
            <ng-container *ngIf="globals.me.id_rol == 1">
                <ul class="sidebar-nav">
                    <li>
                        <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                    </li>
                    <li class="sidebar-separator">
                        <i class="fa fa-ellipsis-h"></i>
                    </li>
                    <!-- <li>
                        <a [routerLink]="['/configuracion']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                    </li> -->
                    <ng-container *ngIf="globals.me.modulos">
                        <li *ngFor="let item of globals.me.modulos">
                            <a [routerLink]="['/'+item.slug]" routerLinkActive="active"><i class="{{item.icono}} sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">{{item.modulo}}</span></a>
                        </li>
                        <li *ngIf="globals.GetModulo('reservas-restaurante')">
                            <a [routerLink]="['/espacios-reservas']" routerLinkActive="active"><i class="icofont-site-map sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Espacios reservas</span></a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="globals.me.modulos && globals.me.modulos.length">
                        <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlaeventos.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Eventos</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/eventos']" routerLinkActive="active" #rlaeventos="routerLinkActive">Eventos</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/espacios']" routerLinkActive="active"><i class="icofont-presentation sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Espacios</span></a>
                                </li>
                                <li>
                                    <a [routerLink]="['/categorias']" routerLinkActive="active"><i class="icofont-list sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Categorías</span></a>
                                </li>
                                <li>
                                    <a [routerLink]="['/organizadores']" routerLinkActive="active"><i class="icofont-people sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Organizadores</span></a>
                                </li>
                                <li *ngIf="globals.me.tarifa == 'spg-event-planner-advanced'">
                                    <a [routerLink]="['/suscriptores']" routerLinkActive="active"><i class="icofont-envelope sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Suscriptores</span></a>
                                </li>
                                <li *ngIf="globals.me.tarifa == 'spg-event-planner-advanced'">
                                    <a [routerLink]="['/mensajes']" routerLinkActive="active"><i class="fa fa-whatsapp sidebar-nav-icon"></i>
                                        <span class="sidebar-nav-mini-hide">Mensajes</span>
                                        <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp">{{globals.cmensajeswhatsapp}}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!globals.me.modulos || !globals.me.modulos.length">
                        <li>
                            <a [routerLink]="['/eventos']" routerLinkActive="active"><i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Eventos</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/espacios']" routerLinkActive="active"><i class="icofont-presentation sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Espacios</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/categorias']" routerLinkActive="active"><i class="icofont-list sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Categorías</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/organizadores']" routerLinkActive="active"><i class="icofont-people sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Organizadores</span></a>
                        </li>
                        <li *ngIf="globals.me.tarifa == 'spg-event-planner-advanced'">
                            <a [routerLink]="['/suscriptores']" routerLinkActive="active"><i class="icofont-envelope sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Suscriptores</span></a>
                        </li>
                        <li *ngIf="globals.me.tarifa == 'spg-event-planner-advanced'">
                            <a [routerLink]="['/mensajes']" routerLinkActive="active"><i class="fa fa-whatsapp sidebar-nav-icon"></i>
                                <span class="sidebar-nav-mini-hide">Mensajes</span>
                                <span class="badge badge-whatsapp" *ngIf="globals && globals.cmensajeswhatsapp">{{globals.cmensajeswhatsapp}}</span>
                            </a>
                        </li>
                    </ng-container>
                    <li class="sidebar-separator">
                        <i class="fa fa-ellipsis-h"></i>
                    </li>
                    <li>
                        <a [routerLink]="['/dinamizadores']" routerLinkActive="active"><i class="icofont-people sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Dinamizadores</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/usuarios']" routerLinkActive="active"><i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                    </li>
                </ul>
            </ng-container>
            <!-- DINAMIZADOR -->
            <ng-container *ngIf="globals.me.id_rol == 2">
                <ul class="sidebar-nav">
                    <li>
                        <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                    </li>
                    <li class="sidebar-separator">
                        <i class="fa fa-ellipsis-h"></i>
                    </li>
                    <li>
                        <a [routerLink]="['/mi-perfil']" routerLinkActive="active"><i class="icofont-user sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mi perfil</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/eventos']" routerLinkActive="active"><i class="icofont-calendar sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Eventos</span></a>
                    </li>
                    <li>
                        <a [routerLink]="['/eventos/add']" routerLinkActive="active"><i class="fa fa-plus sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Nuevo evento</span></a>
                    </li>
                </ul>
            </ng-container>
            <!-- RESERVAS -->
            <ng-container *ngIf="globals.me.id_rol == 4">
                <ul class="sidebar-nav">
                    <li>
                        <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                    </li>
                    <li class="sidebar-separator">
                        <i class="fa fa-ellipsis-h"></i>
                    </li>
                    <ng-container *ngIf="globals.me.modulos">
                        <li *ngFor="let item of globals.me.modulos">
                            <a [routerLink]="['/'+item.slug]" routerLinkActive="active"><i class="{{item.icono}} sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">{{item.modulo}}</span></a>
                        </li>
                        <li *ngIf="globals.GetModulo('reservas-restaurante')">
                            <a [routerLink]="['/espacios-reservas']" routerLinkActive="active"><i class="icofont-site-map sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Espacios reservas</span></a>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div class="licencia sidebar-nav-mini-hide" *ngIf="globals.me && globals.me.licencia">
        <div class="licencia-content-success" *ngIf="!globals.me.licencia.error">
            <b>Licencia activa</b>
            <p *ngIf="globals.me.licencia.fecha_expiracion_format">Hasta el: <span>{{globals.me.licencia.fecha_expiracion_format}}</span></p>
        </div>
        <div class="licencia-content-error" *ngIf="globals.me.licencia.error">
        <b>¡Licencia expirada!</b>
        <a href="https://pecesgordos.es" target="_blank">Por favor, contacte con <b>Peces Gordos Estudio</b> para renovar la licencia.</a>
        </div>
    </div>
    <div class="copyright sidebar-nav-mini-hide">
        <div class="copy-title">SPG Event</div>
        <a class="copy-enlace" href="https://pecesgordos.es" target="_blank">© Peces Gordos Estudio</a>
    </div>
    <!-- END Sidebar Extra Info -->
</div>