<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content" class="dashboard">

                <ng-container *ngIf="globals && globals.me">
                    <!-- SUPER ADMIN -->
                    <ng-container *ngIf="globals.me.id_rol == 0 && !globals.cliente">

                        <div class="row">
                            <div class="col-sm-12 col-lg-12">

                                <h4>Clientes</h4>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/clientes-spa']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-warning">
                                                    <i class="icofont-ui-user-group text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_clientes">{{data.total_clientes}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Clientes</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/clientes-spa/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-warning">
                                                    <i class="icofont-ui-user text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo cliente</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <h4>Equipo</h4>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/usuarios']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-people text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                    <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_usuarios">{{data.total_usuarios}}</span></strong>
                                                </h2>
                                                <span class="text-muted">Usuarios</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-3">
                                        <a [routerLink]="['/usuarios/add']" class="widget">
                                            <div class="widget-content widget-content-mini text-right clearfix">
                                                <div class="widget-icon pull-left themed-background-info">
                                                    <i class="icofont-ui-user text-light-op"></i>
                                                </div>
                                                <h2 class="widget-heading h3">
                                                    <strong><i class="icofont-plus-circle"></i></strong>
                                                </h2>
                                                <span class="text-muted">Nuevo usuario</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <!-- ADMIN -->
                    <ng-container *ngIf="(globals.me.id_rol == 1 || globals.me.id_rol == 2) || globals.cliente">

                        <ng-container *ngIf="globals.GetModulo('reservas-restaurante')">

                            <div class="row">
                                <div class="col-sm-12 col-lg-12">

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <a [routerLink]="['/reservas-restaurante']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-dark">
                                                        <i class="{{globals.GetModulo('reservas-restaurante').icono}} text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-dark" *ngIf="data">
                                                        <strong>&nbsp;
                                                            <span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span>
                                                            <ng-container *ngIf="data.total_reservas_personas">
                                                                <small style="font-weight:bold;"> / </small>
                                                                <small data-toggle="counter" [attr.data-to]="data.total_reservas_personas" style="font-weight:bold;">{{data.total_reservas_personas}} pers.</small>
                                                            </ng-container>
                                                        </strong>
                                                    </h2>
                                                    <span class="text-muted">Reservas hoy</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <a [routerLink]="['/reservas-restaurante']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-dark">
                                                        <i class="icofont-people text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-dark" *ngIf="data">
                                                        <strong>&nbsp;
                                                            <span data-toggle="counter" [attr.data-to]="data.total_clientes">{{data.total_clientes}}</span>
                                                        </strong>
                                                    </h2>
                                                    <span class="text-muted">Clientes</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <a [routerLink]="['/eventos']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left bg-primary">
                                                        <i class="icofont-ui-calendar text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-primary" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_eventos">{{data.total_eventos}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Eventos</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <a [routerLink]="['/sugerencias']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-dark">
                                                        <i class="{{globals.GetModulo('sugerencias').icono}} text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-dark">&nbsp;</h2>
                                                    <span class="text-muted">Sugerencias</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <div class="widget" style="min-width:306px;max-width:400px;">
                                        <div class="widget-content border-bottom" style="background-color:#374249;color:#FFF;">
                                            Calendario de reservas
                                        </div>
                                        <div class="widget-content border-bottom themed-background-muted" style="padding:0;text-align:center;">
                                            <div id="calendario-reservas" style="font-size:.9em;"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <div class="widget cursor" [routerLink]="['/reservas-restaurante']">
                                        <div class="widget-content border-bottom" style="background-color:#374249;color:#FFF;">
                                            Reservas de hoy
                                        </div>
                                        <div class="widget widget-con-imagen">
                                            <div class="widget-scroll">
                                                <ul class="list-group" *ngIf="reservas && reservas.length">
                                                    <li class="list-group-item" *ngFor="let item of reservas">
                                                        <div class="font-bold text-primary">{{item.nombre}}</div>
                                                        <div>{{item.hora}}h
                                                            <span *ngIf="item.espacio">&nbsp;&nbsp;{{item.espacio}}</span>
                                                            <span *ngIf="item.personas">&nbsp;&nbsp;<strong>{{item.personas}}</strong> pers.</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <p *ngIf="!reservas || !reservas.length" class="text-center" style="padding: 50px 5px;">No hay reservas para hoy</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <div class="widget cursor" [routerLink]="['/eventos']">
                                        <div class="widget-content border-bottom" style="background-color:var(--color-primary);color:#FFF;">
                                            Próximo evento
                                        </div>
                                        <div class="widget widget-con-imagen" *ngIf="data && data.proximo_evento">
                                            <div class="widget-scroll">
                                                <div class="widget-content widget-content-mini ">
                                                    <strong class="text-dark font-16" style="line-height:1.2;">{{data.proximo_evento.titulo}}</strong>
                                                </div>
                                                <div class="widget-content clearfix">
                                                    <img [src]="data.proximo_evento.imagen" *ngIf="data.proximo_evento.imagen" class="width-100">
                                                    <!-- <div class="m-t-10 descripcion" [innerHTML]="data.proximo_evento.descripcion"></div> -->
                                                    <div class="localidad m-t-10" *ngIf="data.proximo_evento.localidad"><i class="fa fa-map-marker text-primary"></i> {{data.proximo_evento.localidad}}</div>
                                                    <div class="m-t-10" *ngIf="data.proximo_evento.fecha_inicio_format">
                                                        <i class="fa fa-calendar text-primary"></i> {{data.proximo_evento.fecha_inicio_format}}
                                                        <span *ngIf="data.proximo_evento.fecha_fin_format && data.proximo_evento.fecha_fin_format != data.proximo_evento.fecha_inicio_format">- {{data.proximo_evento.fecha_fin_format}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <div class="widget cursor" [routerLink]="['/sugerencias']">
                                        <div class="widget-content border-bottom" style="background-color:#374249;color:#FFF;">
                                            Sugerencia de esta semana
                                        </div>
                                        <div class="widget widget-con-imagen" *ngIf="data && data.sugerencia">
                                            <div class="widget-scroll">
                                                <div class="widget-content widget-content-mini ">
                                                    <strong class="text-dark font-16" style="line-height:1.2;">{{data.sugerencia.titulo}}</strong>
                                                </div>
                                                <div class="widget-content text-right clearfix">
                                                    <img [src]="data.sugerencia.imagen" *ngIf="data.sugerencia.imagen" class="width-100">
                                                    <h2 class="widget-heading h3 text-primary"><strong>{{data.sugerencia.precio_format}} €</strong></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div class="widget">
                                        <div class="widget-content border-bottom" style="background-color:#374249;color:#FFF;">
                                            <span class="pull-right">{{anio}}</span>
                                            Gráfica de reservas anuales
                                        </div>
                                        <div class="widget-content border-bottom themed-background-muted">
                                            <div id="chart-reservas" style="height:18vh;"></div>
                                        </div>
                                        <div class="widget-content widget-content-full">
                                            <div class="row text-center">
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-right">
                                                    <h3 class="font-14 font-bold m-b-0">Reservas<br>esta semana</h3>
                                                    <span class="font-18">{{data.total_reservas_semana}}</span>
                                                    <span *ngIf="data.total_reservas_personas_semana" class="text-muted"><br>{{data.total_reservas_personas_semana}} pers.</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom">
                                                    <h3 class="font-14 font-bold m-b-0">Reservas<br>este mes</h3>
                                                    <span class="font-18">{{data.total_reservas_mes}}</span>
                                                    <span *ngIf="data.total_reservas_personas_mes" class="text-muted"><br>{{data.total_reservas_personas_mes}} pers.</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-left">
                                                    <h3 class="font-14 font-bold m-b-0">Reservas<br>este año</h3>
                                                    <span class="font-18">{{data.total_reservas_anio}}</span>
                                                    <span *ngIf="data.total_reservas_personas_anio" class="text-muted"><br>{{data.total_reservas_personas_anio}} pers.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div class="widget">
                                        <div class="widget-content border-bottom" style="background-color:var(--color-primary);color:#FFF;">
                                            <span class="pull-right">{{anio}}</span>
                                            Gráfica de eventos anuales
                                        </div>
                                        <div class="widget-content border-bottom themed-background-muted">
                                            <div id="chart-eventos" style="height:18vh"></div>
                                        </div>
                                        <div class="widget-content widget-content-full">
                                            <div class="row text-center">
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-right">
                                                    <h3 class="font-14 font-bold m-b-0">Eventos<br>esta semana</h3>
                                                    <span class="font-18">{{data.total_eventos_semana}}</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom">
                                                    <h3 class="font-14 font-bold m-b-0">Eventos<br>este mes</h3>
                                                    <span class="font-18">{{data.total_eventos_mes}}</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-left">
                                                    <h3 class="font-14 font-bold m-b-0">Eventos<br>este año</h3>
                                                    <span class="font-18">{{data.total_eventos_anio}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="!globals.GetModulo('reservas-restaurante')">

                            <div class="row">
                                <div class="col-sm-12 col-lg-12">

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                            <a [routerLink]="['/eventos']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left bg-primary">
                                                        <i class="icofont-ui-calendar text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-primary" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_eventos">{{data.total_eventos}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Eventos</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                            <a [routerLink]="['/usuarios']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-info">
                                                        <i class="icofont-people text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-info" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_usuarios">{{data.total_usuarios}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Usuarios</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-6" *ngIf="globals.me.tarifa == 'spg-event-planner-advanced'">
                                            <div class="form-group">
                                                <label>Aviso temporal</label>
                                                <div class="input-group width-100">
                                                    <input type="text" class="form-control" [(ngModel)]="texto_aviso" placeholder="Texto de aviso para mostrar en web" maxlength="500">
                                                    <div class="input-group-append cursor" (click)="GuardarAviso()">
                                                      <span class="input-group-addon btn btn-primary btn-guardar-aviso" style="font-size:14px;color:#FFF;padding:9px;">Guardar <i class="fa fa-check"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                    <div class="widget cursor" [routerLink]="['/eventos']">
                                        <div class="widget-content border-bottom" style="background-color:var(--color-primary);color:#FFF;">
                                            Próximo evento
                                        </div>
                                        <div class="widget widget-con-imagen" *ngIf="data && data.proximo_evento">
                                            <div class="widget-content widget-content-mini ">
                                                <strong class="text-dark font-16" style="line-height:1.2;">{{data.proximo_evento.titulo}}</strong>
                                            </div>
                                            <div class="widget-content clearfix">
                                                <img [src]="data.proximo_evento.imagen" *ngIf="data.proximo_evento.imagen" class="width-100">
                                                <div class="localidad m-t-10" *ngIf="data.proximo_evento.localidad"><i class="fa fa-map-marker text-primary"></i> {{data.proximo_evento.localidad}}</div>
                                                <div class="m-t-10" *ngIf="data.proximo_evento.fecha_inicio_format">
                                                    <i class="fa fa-calendar text-primary"></i> {{data.proximo_evento.fecha_inicio_format}}
                                                    <span *ngIf="data.proximo_evento.fecha_fin_format && data.proximo_evento.fecha_fin_format != data.proximo_evento.fecha_inicio_format">- {{data.proximo_evento.fecha_fin_format}}</span>
                                                </div>
                                                <div class="m-t-10 descripcion" [innerHTML]="data.proximo_evento.descripcion"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                                    <div class="widget">
                                        <div class="widget-content border-bottom" style="background-color:var(--color-primary);color:#FFF;">
                                            <span class="pull-right">{{anio}}</span>
                                            Gráfica de eventos anuales
                                        </div>
                                        <div class="widget-content border-bottom themed-background-muted">
                                            <div id="chart-eventos" style="height:22vh"></div>
                                        </div>
                                        <div class="widget-content widget-content-full">
                                            <div class="row text-center">
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-right">
                                                    <h3 class="font-14 font-bold m-b-0">Eventos<br>esta semana</h3>
                                                    <span class="font-18">{{data.total_eventos_semana}}</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom">
                                                    <h3 class="font-14 font-bold m-b-0">Eventos<br>este mes</h3>
                                                    <span class="font-18">{{data.total_eventos_mes}}</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-left">
                                                    <h3 class="font-14 font-bold m-b-0">Eventos<br>este año</h3>
                                                    <span class="font-18">{{data.total_eventos_anio}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                                    <div class="widget cursor" [routerLink]="['/eventos']">
                                        <div class="widget-content border-bottom" style="background-color:var(--color-primary);color:#FFF;">
                                            Eventos más visitados
                                        </div>
                                        <div class="widget-content border-bottom themed-background-muted">
                                            <div id="chart-visitas" style="height:22vh"></div>
                                        </div>
                                        <div class="widget-content widget-content-full">
                                            <ul class="list-group" *ngIf="data.eventos_mas_visitados && data.eventos_mas_visitados.length">
                                                <li class="list-group-item font-13" *ngFor="let item of data.eventos_mas_visitados;let i = index">
                                                    <div style="float:left;height:40px;margin-right:10px">#{{i+1}} </div>
                                                    <div class=""><span class="font-bold">{{item.titulo}}</span></div>
                                                    <div class="text-primary">{{item.visitas}} visitas</div>
                                                </li>
                                            </ul>
                                            <p *ngIf="!data.eventos_mas_visitados || !data.eventos_mas_visitados.length">No visitas aún</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                    <!-- RESERVAS -->
                    <ng-container *ngIf="globals.me.id_rol == 4">

                        <ng-container *ngIf="globals.GetModulo('reservas-restaurante')">

                            <div class="row">
                                <div class="col-sm-12 col-lg-12">

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <a [routerLink]="['/reservas-restaurante']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-dark">
                                                        <i class="{{globals.GetModulo('reservas-restaurante').icono}} text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-dark" *ngIf="data">
                                                        <strong>&nbsp;
                                                            <span data-toggle="counter" [attr.data-to]="data.total_reservas">{{data.total_reservas}}</span>
                                                            <ng-container *ngIf="data.total_reservas_personas">
                                                                <small style="font-weight:bold;"> / </small>
                                                                <small data-toggle="counter" [attr.data-to]="data.total_reservas_personas" style="font-weight:bold;">{{data.total_reservas_personas}} pers.</small>
                                                            </ng-container>
                                                        </strong>
                                                    </h2>
                                                    <span class="text-muted">Reservas hoy</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                            <a [routerLink]="['/reservas-restaurante']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-dark">
                                                        <i class="icofont-people text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-dark" *ngIf="data">
                                                        <strong>&nbsp;
                                                            <span data-toggle="counter" [attr.data-to]="data.total_clientes">{{data.total_clientes}}</span>
                                                        </strong>
                                                    </h2>
                                                    <span class="text-muted">Clientes</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <div class="widget" style="min-width:306px;max-width:400px;">
                                        <div class="widget-content border-bottom" style="background-color:#374249;color:#FFF;">
                                            Calendario de reservas
                                        </div>
                                        <div class="widget-content border-bottom themed-background-muted" style="padding:0;text-align:center;">
                                            <div id="calendario-reservas" style="font-size:.9em;"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <div class="widget cursor" [routerLink]="['/reservas-restaurante']">
                                        <div class="widget-content border-bottom" style="background-color:#374249;color:#FFF;">
                                            Reservas de hoy
                                        </div>
                                        <div class="widget widget-con-imagen">
                                            <div class="widget-scroll">
                                                <ul class="list-group" *ngIf="reservas && reservas.length">
                                                    <li class="list-group-item" *ngFor="let item of reservas">
                                                        <div class="font-bold text-primary">{{item.nombre}}</div>
                                                        <div>{{item.hora}}h
                                                            <span *ngIf="item.espacio">&nbsp;&nbsp;{{item.espacio}}</span>
                                                            <span *ngIf="item.personas">&nbsp;&nbsp;<strong>{{item.personas}}</strong> pers.</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <p *ngIf="!reservas || !reservas.length" class="text-center" style="padding: 50px 5px;">No hay reservas para hoy</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div class="widget">
                                        <div class="widget-content border-bottom" style="background-color:#374249;color:#FFF;">
                                            <span class="pull-right">{{anio}}</span>
                                            Gráfica de reservas anuales
                                        </div>
                                        <div class="widget-content border-bottom themed-background-muted">
                                            <div id="chart-reservas" style="height:18vh;"></div>
                                        </div>
                                        <div class="widget-content widget-content-full">
                                            <div class="row text-center">
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-right">
                                                    <h3 class="font-14 font-bold m-b-0">Reservas<br>esta semana</h3>
                                                    <span class="font-18">{{data.total_reservas_semana}}</span>
                                                    <span *ngIf="data.total_reservas_personas_semana" class="text-muted"><br>{{data.total_reservas_personas_semana}} pers.</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom">
                                                    <h3 class="font-14 font-bold m-b-0">Reservas<br>este mes</h3>
                                                    <span class="font-18">{{data.total_reservas_mes}}</span>
                                                    <span *ngIf="data.total_reservas_personas_mes" class="text-muted"><br>{{data.total_reservas_personas_mes}} pers.</span>
                                                </div>
                                                <div class="col-xs-12 col-sm-4 push-inner-top-bottom border-left">
                                                    <h3 class="font-14 font-bold m-b-0">Reservas<br>este año</h3>
                                                    <span class="font-18">{{data.total_reservas_anio}}</span>
                                                    <span *ngIf="data.total_reservas_personas_anio" class="text-muted"><br>{{data.total_reservas_personas_anio}} pers.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>
                </ng-container>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>