import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './backend/login/login.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './classes/globals.class';
import { MenuComponent } from './backend/controls/menu/menu.component';
import { HeaderComponent } from './backend/controls/header/header.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { ModalReservaComponent } from './backend/controls/modal-reserva/modal-reserva.component';
import { ClientesSpaComponent } from './backend/superadmin/clientes-spa/clientes-spa.component';
import { EditClienteSpaComponent } from './backend/superadmin/clientes-spa/edit-cliente-spa/edit-cliente-spa.component';
import { CalendarioComponent } from './backend/calendario/calendario.component';
import { EventosComponent } from './backend/eventos/eventos.component';
import { EditEventoComponent } from './backend/eventos/edit-evento/edit-evento.component';
import { EspaciosComponent } from './backend/espacios/espacios.component';
import { CategoriasComponent } from './backend/categorias/categorias.component';
import { SugerenciasComponent } from './backend/sugerencias/sugerencias.component';
import { ReservasRestauranteComponent } from './backend/reservas-restaurante/reservas-restaurante.component';
import { SugerenciasFrontComponent } from './frontend/sugerencias-front/sugerencias-front.component';
import { SuscriptoresComponent } from './backend/suscriptores/suscriptores.component';
import { EspaciosReservasComponent } from './backend/espacios-reservas/espacios-reservas.component';
import { ConfigSuscripcionComponent } from './frontend/config-suscripcion/config-suscripcion.component';
import { MensajesComponent } from './backend/mensajes/mensajes.component';
import { OrganizadoresComponent } from './backend/organizadores/organizadores.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    MenuComponent,
    HeaderComponent,
    ConfigAppComponent,
    UsuariosComponent,
    EditUsuarioComponent,
    ConfiguracionComponent,
    MiPerfilComponent,
    ModalReservaComponent,
    ClientesSpaComponent,
    EditClienteSpaComponent,
    CalendarioComponent,
    EventosComponent,
    EditEventoComponent,
    EspaciosComponent,
    CategoriasComponent,
    SugerenciasComponent,
    ReservasRestauranteComponent,
    SugerenciasFrontComponent,
    SuscriptoresComponent,
    EspaciosReservasComponent,
    ConfigSuscripcionComponent,
    MensajesComponent,
    OrganizadoresComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
