import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-backend-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  public CMensajesWhatsApp:any = null;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { 
    this.globals.Api('/configuracion').subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      this.MensajesWhatsApp();
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      App.init();
    }, 500);
  }
  NuevaReserva() {
    if ($.NuevaReserva) $.NuevaReserva();
  }
  MensajesWhatsApp() {
    let timeout:number = 10000;
    clearTimeout($.IDTimeMensajesCount);
    if (!this.globals.config || !this.globals.config.whatsapp_fbid) {
      this.globals.cmensajeswhatsapp = null;
      $.IDTimeMensajesCount = setTimeout(() => { this.MensajesWhatsApp(); }, timeout);
      return;
    }
    this.globals.Api('/crm-mensajes-count', {fbid: this.globals.config.whatsapp_fbid}).subscribe((data:any) => {
      if (!data || data.error) return;
      this.globals.cmensajeswhatsapp = data.value;
      $.IDTimeMensajesCount = setTimeout(() => { this.MensajesWhatsApp(); }, timeout);
    }, (error:any) => {
      $.IDTimeMensajesCount = setTimeout(() => { this.MensajesWhatsApp(); }, timeout);
    });
  }

}
