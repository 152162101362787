import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let L: any;

@Component({
  selector: 'app-espacios',
  templateUrl: './espacios.component.html'
})
export class EspaciosComponent implements OnInit {
  public title:string = 'Espacios';
  public data:any = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null
  };

  public dataitem:any = {};
  public salas:any = [];
  public usuarios:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    this.globals.Api('/usuarios', {id_rol: '2'}).subscribe(data => {
      if (!data || !data.length) return;
      this.usuarios = data;
      $(document).ready(() => {
        $('.chosen-select').chosen({width: "100%"});
      });
    });
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/evt-espacios', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/evt-espacios/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    $('#modal-crear-espacio').modal('show');
    $('#modal-crear-espacio .modal-title').html('Nuevo espacio');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    this.salas = [];
    this.dataitem.salas = [];
    setTimeout(() => {
      $('#modal-crear-espacio input[type="text"]')[0].focus();
    }, 500);
    $('#txtSala')[0].onkeyup = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.AddSala();
      }
    };
    setTimeout(() => {
      $('.chosen-select').trigger('chosen:updated');
      $('.chosen-select').trigger('change');
    }, 200);
  }
  Editar(item:any) {
    this.dataitem = JSON.parse(JSON.stringify(item));
    if (this.dataitem.salas) this.salas = this.dataitem.salas;
    $('#modal-crear-espacio').modal('show');
    $('#modal-crear-espacio .modal-title').html(this.dataitem.espacio);
    setTimeout(() => {
      $('#modal-crear-espacio input[type="text"]')[0].focus();
    }, 500);
    $('#txtSala')[0].onkeyup = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.AddSala();
      }
    };
    setTimeout(() => {
      $('.chosen-select').trigger('chosen:updated');
      $('.chosen-select').trigger('change');
    }, 200);
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar espacio?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/evt-espacios-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  AddSala() {
    if (!this.dataitem.sala || this.dataitem.sala == '') return;
    this.dataitem.editandosalas = true;
    this.salas.push({id: '', sala: this.dataitem.sala});
    this.dataitem.sala = '';
    this.dataitem.editandosalas = true;
  }
  EditarSala(item:any, event:any) {
    if (event.target.innerText == '') {
      event.target.innerText = item.sala;
      return;
    }
    if (event.target.innerText == item.sala) return;
    item.sala = event.target.innerText;
  }
  BorrarSala(item:any) {
    this.dataitem.editandosalas = true;
    this.salas.splice(this.salas.findIndex(el => el.sala == item.sala), 1);
  }
  ChangeDinamizadorSala(item:any, event:any) {
    let id = event.target.value;
    this.globals.Api('/evt-sala-edit-usuario', {id: item.id, id_usuario: id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
    });
  }
  GuardarModal() {
    if (
      this.dataitem.espacio == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    this.dataitem.salas = this.salas;
    this.dataitem.id_usuario = $('#cmbDinamizador').val();

    $.buttonloading('btnGuardarModal', 'Enviando');
    if (!this.dataitem.id || this.dataitem.id == '') {
      this.globals.Api('/evt-espacios-add', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-espacio').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    } else {
      this.globals.Api('/evt-espacios-edit', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btnGuardarModal');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-espacio').modal('hide');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btnGuardarModal');
      });
    }
  }
  AbrirMapa() {
    $('#modal-mapa').modal('show');
    $('#mapa-container').html('<div id="mapa" style="width:100%;height:400px;"></div>');
    $('#mapa').removeAttr('class');
    setTimeout(() => {
      let point = [40.036198, -6.088190];
      $.localizaciontemp = point[0]+','+point[1];
      var mymap = L.map('mapa').setView(point, 14);
      if (this.dataitem.localizacion) {
        point = [this.dataitem.localizacion.split(',')[0], this.dataitem.localizacion.split(',')[1]];
        $.localizaciontemp = point[0]+','+point[1];
        mymap.setView(point, 14);
      }
      L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      }).addTo(mymap);
      var marker = L.marker(point).addTo(mymap);
      mymap.on('click', function(e) {
        marker.setLatLng([e.latlng.lat, e.latlng.lng]);
        $.localizaciontemp = e.latlng.lat+','+e.latlng.lng;
      });
    }, 500);
  }
  SelecLocalizacion() {
    if ($.localizaciontemp) this.dataitem.localizacion_maps = $.localizaciontemp;
    $('#modal-mapa').modal('hide');
  }
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.dataitem.img = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  DelImagen() {
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.dataitem.id || this.dataitem.id == '') {
        this.dataitem.img = '';
        this.dataitem.imagen = '';
        return;
      }
      this.globals.Api('/evt-espacios-imagen-del', {id: this.dataitem.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.dataitem.img = '';
        this.dataitem.imagen = '';
        this.appref.tick();
      });
    });
  }

}
