<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Reservas</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="Refrescar()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Reservas</li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva reserva</a>
                        </div>
                    </div>
                </div>
                
                
                <div class="row">
                    <div class="col-lg-4 col-lg-push-8 col-md-12 col-xs-12">
                        <div class="block full calendar-container">
                            <div class="block-title">
                                <h2>Calendario</h2>
                            </div>
                            <div id="calendario"></div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-lg-pull-4 col-md-12 col-xs-12">
                        <div class="block full">
                            <div class="block-title">
                                <div class="pull-right btn-group btn-turnos">
                                    <button type="button" class="btn btn-default" (click)="tipovista = ''">Todas</button>
                                    <button type="button" class="btn btn-default btn-comida" (click)="tipovista = 'Comida'"><i class="icofont-sun"></i> Turno comidas <span>({{TotalComidas()}})</span></button>
                                    <button type="button" class="btn btn-default btn-cena" (click)="tipovista = 'Cena'"><i class="icofont-night"></i> Turno cenas <span>({{TotalCenas()}})</span></button>
                                </div>
                                <h2>Reservas 
                                    <span class="text-primary m-l-5 font-20" *ngIf="params.fecha_format">{{params.fecha_format}}</span>
                                    <span class="m-l-15 font-16" *ngIf="reservas && reservas.length"><span style="font-weight: normal;text-transform: none;">Total:</span> {{reservas.length}}</span>
                                    <span class="m-l-15 font-16" *ngIf="reservas && reservas.length"><span style="font-weight: normal;text-transform: none;">Nº Personas:</span> {{TotalPersonas()}}</span>
                                </h2>
                            </div>

                            <div class="input-group width-100 m-b-5">
                              <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                              <div class="input-group-append cursor" (click)="Buscar()" style="width:45px;">
                                <span class="input-group-addon"><i class="fa fa-search"></i></span>
                              </div>
                            </div>

                            <div class="badges-espacios" *ngIf="espacios && espacios.length">
                                <a class="badge all" (click)="SetEspacio()">
                                    Todos los espacios
                                </a>
                                <a class="badge" *ngFor="let item of espacios" (click)="SetEspacio(item)">
                                    {{item.espacio}}
                                    <span *ngIf="TotalPersonasEspacio(item)">( <b>{{TotalPersonasEspacio(item)}}</b> pers.)</span>
                                </a>
                            </div>

                            <h5 class="font-20 font-bold m-t-20" *ngIf="tipovista && tipovista != ''">
                                {{tipovista}} <span *ngIf="espacio" style="text-transform:lowercase;">en {{espacio.espacio}}</span> <span class="text-primary m-l-15">{{TotalPersonasTurno()}} personas</span>
                            </h5>
                            <h5 class="font-20 font-bold m-t-20" *ngIf="tipovista == '' && espacio">
                                {{espacio.espacio}} <span class="text-primary m-l-15">{{TotalPersonasEspacio(espacio)}} personas</span>
                            </h5>
                            <div class="row">
                                <ng-container *ngFor="let item of reservas">
                                    <div class="col-lg-6 col-md-12" *ngIf="tipovista == '' || tipovista == item.turno">
                                        <a href="javascript:void(0)" class="widget reserva-restaurante {{item.turno == 'Comida' ? 'comida' : 'cena'}}" (click)="Editar(item)">
                                            <div class="widget-content text-right clearfix">
                                                <div class="pull-left">
                                                    <i class="fa fa-cutlery sidebar-nav-icon img-circle img-thumbnail img-thumbnail-avatar"
                                                        style="font-size:35px;text-align:center;color:var(--color-primary);line-height: 1.6;"></i>
                                                    <h3 class="text-left h4">Hora: <strong>{{item.hora}}</strong></h3>
                                                    <h3 class="text-left h4" *ngIf="item.espacio">En: <strong>{{item.espacio}}</strong></h3>
                                                    <span *ngIf="item.usuario" class="autor">Creada por: {{item.usuario}}</span>
                                                </div>
                                                <h3 class="widget-heading h4"><strong>{{item.nombre}}</strong></h3>
                                                <div class="h4"><strong>{{item.personas}}</strong> personas</div>
                                                <div class="text-muted breakword" *ngIf="item.email"><i class="fa fa-envelope-o"></i> {{item.email}}</div>
                                                <div class="text-muted" *ngIf="item.telefono"><i class="fa fa-phone"></i> {{item.telefono}}</div>
                                                <div class="m-t-10">
                                                    <a class="btn btn-sm btn-warning" (click)="EnviarEmail(item, $event)"><i class="fa fa-envelope font-14"></i></a>
                                                    <a class="btn btn-sm btn-success m-l-10" (click)="EnviarWhatsapp(item, $event)"><i class="fa fa-whatsapp font-14"></i></a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </ng-container>
                            </div>
                            
                            <div class="no-resultados" *ngIf="!reservas || !reservas.length">
                                <div>No existen reservas para esta fecha.</div>
                                <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                            </div>
                            <div class="text-center p-t-10" style="border-top: 1px solid #EEE" *ngIf="reservas && reservas.length">
                                <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir reserva</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal fade" id="modal-crear-reserva" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header flex">
            <h4 class="modal-title"><i class="icon-list"></i> Nueva reseva</h4>
            <button type="button" class="close" data-dismiss="modal" style="margin-left: auto;font-size: 34px;"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Fecha <span class="text-danger">*</span></label>
                <div class="col-lg-8 col-xs-8">
                    <input type="date" class="form-control" [(ngModel)]="dataitem.alta" maxlength="200">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Hora <span class="text-danger">*</span></label>
                <div class="col-lg-8 col-xs-8">
                    <input type="time" class="form-control" [(ngModel)]="dataitem.hora" maxlength="5" step="300">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Nombre y apellidos <span class="text-danger">*</span></label>
                <div class="col-lg-8 col-xs-8">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.nombre" maxlength="200">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Código postal</label>
                <div class="col-lg-8 col-xs-8">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.codpostal" maxlength="25">
                </div>
            </div>
            <div class="form-group row noselect">
                <label class="col-lg-4 col-xs-4 col-form-label">Personas <span class="text-danger">*</span></label>
                <div class="col-lg-8 col-xs-8">
                    <a class="btn-circle-sm" style="float:right" onclick="$('#txt-personas,.selector-numero').toggle();"><i class="fa fa-pencil"></i></a>
                    <div class="selector-numero">
                        <a class="btn-menos" (click)="Menos('personas')" [attr.disabled]="dataitem.personas <= 1"><i class="fa fa-minus-circle"></i></a>
                        <span>{{dataitem.personas}}</span>
                        <a class="btn-mas" (click)="Mas('personas')"><i class="fa fa-plus-circle"></i></a>
                    </div>
                    <input type="number" id="txt-personas" class="form-control" [(ngModel)]="dataitem.personas" maxlength="20" style="display:none;width:70%;font-size:20px;">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Espacio</label>
                <div class="col-lg-8 col-xs-8">
                    <!-- <input type="text" class="form-control" list="espacios" [(ngModel)]="dataitem.espacio" maxlength="50">
                    <datalist id="espacios">
                        <option [value]="item.espacio" *ngFor="let item of espacios"></option>
                    </datalist> -->
                    <select class="form-control" [(ngModel)]="dataitem.espacio">
                        <option [value]="item.espacio" *ngFor="let item of espacios">{{item.espacio}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Teléfono</label>
                <div class="col-lg-8 col-xs-8">
                    <input type="tel" class="form-control" [(ngModel)]="dataitem.telefono" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Email</label>
                <div class="col-lg-8 col-xs-8">
                    <input type="email" class="form-control" [(ngModel)]="dataitem.email" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-xs-4 col-form-label">Notas</label>
                <div class="col-lg-8 col-xs-8">
                    <textarea class="form-control" placeholder="Notas o comentarios ..." [(ngModel)]="dataitem.notas" maxlength="5000" rows="3"></textarea>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-footer flex" style="justify-content: right;">
            <button type="button" class="btn btn-danger" *ngIf="dataitem.id && dataitem.id != ''" style="margin-left: 0;" (click)="Borrar(dataitem)">
                <i class="fa fa-trash desktop-hidden"></i>
                <span class="mobile-hidden">Eliminar</span>
            </button>
            <button class="btn btn-sm btn-warning" *ngIf="dataitem.id && dataitem.id != ''" (click)="EnviarEmail(dataitem, $event)"><i class="fa fa-envelope font-14"></i></button>
            <button class="btn btn-sm btn-success m-l-10" *ngIf="dataitem.id && dataitem.id != ''" (click)="EnviarWhatsapp(dataitem, $event)" style="margin-right: auto;"><i class="fa fa-whatsapp font-14"></i></button>
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarModal" type="button" class="btn btn-primary" (click)="GuardarModal()">Guardar</button>
        </div>
        </div>
    </div>
</div>