import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-suscriptores',
  templateUrl: './suscriptores.component.html'
})
export class SuscriptoresComponent implements OnInit {
  public title:string = 'Suscriptores';
  public data:any = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null
  };
  public datageneral:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    this.globals.Api('/datos-generales').subscribe(data => {
      if (!data) return;
      this.datageneral = data;
    });
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/evt-suscriptores', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.data = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.data = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/evt-suscriptores/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  CambiarPermiso(item:any) {
    this.globals.Api('/evt-suscriptor-permiso', {id: item.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      item.permitir_notificaciones = data.value;
      this.appref.tick();
    });
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar suscriptor?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/evt-suscriptores-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.splice(this.data.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}