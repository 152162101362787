import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let tinymce: any;
declare let L: any;

@Component({
  selector: 'app-edit-evento',
  templateUrl: './edit-evento.component.html'
})
export class EditEventoComponent implements OnInit {
  public title:string = 'Nuevo evento';
  public data:any = {localidad: '', id_organizador: '', id_sala: ''};
  public imgsubir:string = '';
  public categorias = [];
  public organizadores = [];
  public espacios = [];
  public salas = [];
  public localidades = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    // if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo evento';
    } else {
      this.title = this.data.titulo;
    }
    if (!this.data.id_organizador) this.data.id_organizador = '';
    if (!this.data.id_sala) this.data.id_sala = '';
    this.CargarCategorias();
    this.CargarOrganizadores();
    this.CargarEspacios();
    this.globals.Api('/localidades', {provincias: "'Cáceres', 'Badajoz'"}).subscribe(data => {
      if (!data || data.length == 0) return;
      this.localidades = data;
    });
    let that = this;
    tinymce.remove();
    $(document).ready(() => {
      tinymce.init({
        selector:'.text-editor',
        height: 400,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code',
        toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | code',
        language: 'es',
        relative_urls: false,
        remove_script_host : false,
        convert_urls : false,
        paste_data_images: true,
        images_upload_handler: function (blobInfo, success, failure) {
          var image_size = blobInfo.blob().size / 1000;  // image size in kbytes
          var max_size   = 1024;                         // max size in kbytes
          if ( image_size  > max_size ) {
            alert('La imagen es demasiado grande ( '+ image_size  + '), Máximo: ' + max_size + ' kb');
            failure('La imagen es demasiado grande ( '+ image_size  + '), Máximo: ' + max_size + ' kb', { remove: true });
            return;
          } else {
            let id_cliente:any = null;
            if (that.globals.cliente) id_cliente = that.globals.cliente.id;
            if (that.globals.me.id_cliente) id_cliente = that.globals.me.id_cliente;
            that.globals.Api('/pub-upload', {img: blobInfo.base64(), id_cliente: id_cliente}).subscribe(data => {
              if (!data || !data.url || data.url == '') {
                failure('Error al subir la imagen', { remove: true });
                return;
              }
              success(data.url);
            }, error => {
              failure('Error al subir la imagen', { remove: true });
            });
          }
        }
      });
    });
    if (this.data.descripcion) tinymce.EditorManager.get('txtdescripcion').setContent(this.data.descripcion);
    $("#txtFechaEvento").datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy",
    });
    if (this.data.fecha_inicio_format) $('#txtFechaEvento').datepicker('setDate', this.data.fecha_inicio_format);
    $("#txtFechaFinalizacion").datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy",
    });
    if (this.data.fecha_fin_format) $('#txtFechaFinalizacion').datepicker('setDate', this.data.fecha_fin_format);
    $('#txturlexterna').on('paste', function (e) {
      const dT = e.clipboardData || e.originalEvent.clipboardData;
      const html = dT.getData('text/html') || "";
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const img = parsed.querySelector('img');
      if( !img ) {
        setTimeout(() => {
          that.data.imagen = $('#txturlexterna').val();
          that.appref.tick();
        }, 50);
      } else {
        const url = img.src;
        that.data.imagen = url;
        that.data.img_externa = url;
        that.appref.tick();
      }
    });
    if (this.globals.cliente) {
      this.globals.me.tarifa = this.globals.cliente.tarifa;
      this.globals.me.tarifa_nombre = this.globals.cliente.tarifa_nombre;
    }
  }
  CargarCategorias() {
    this.globals.Api('/evt-categorias').subscribe(data => {
      if (!data || data.length == 0) return;
      this.categorias = data;
      if (!this.data.id_categoria && this.categorias.length) this.data.id_categoria = this.categorias[0].id;
    });
  }
  CargarOrganizadores() {
    this.globals.Api('/evt-organizadores').subscribe(data => {
      if (!data || data.length == 0) return;
      this.organizadores = data;
    });
  }
  CargarEspacios() {
    this.globals.Api('/evt-espacios').subscribe(data => {
      if (!data || data.length == 0) return;
      this.espacios = data;
      let espacio:any = null;
      if (!this.data.id_espacio && this.espacios.length) {
        espacio = this.espacios[0];
      } else {
        espacio = this.espacios.find(el => el.id == this.data.id_espacio);
      }
      if (espacio) {
        this.data.id_espacio = espacio.id;
        this.data.localizacion_maps = espacio.localizacion_maps;
        if (espacio.salas) {
          this.salas = espacio.salas;
          if (!this.data.id_sala && this.salas.length) this.data.id_sala = this.salas[0].id;
        } else {
          this.CargarSalas();
        }
      }
    });
  }
  CargarSalas() {
    this.globals.Api('/evt-salas', {id_espacio: this.data.id_espacio}).subscribe(data => {
      if (!data || data.length == 0) return;
      this.salas = data;
      if (!this.data.id_sala && this.salas.length) this.data.id_sala = this.salas[0].id;
    });
  }
  ChangeEspacio() {
    if (this.data.id_espacio) {
      this.globals.Api('/evt-espacios', {id: this.data.id_espacio}).subscribe(data => {
        if (!data || !data.length) return;
        let espacio:any = data[0];
        if (espacio) {
          this.data.id_espacio = espacio.id;
          this.data.localizacion_maps = espacio.localizacion_maps;
          if (espacio.salas) {
            this.salas = espacio.salas;
            if (!this.data.id_sala && this.salas.length) this.data.id_sala = this.salas[0].id;
          } else {
            this.globals.Api('/evt-salas', {id_espacio: this.data.id_espacio}).subscribe(data => {
              if (!data || data.length == 0) return;
              this.salas = data;
              if (!this.data.id_sala && this.salas.length) this.data.id_sala = this.salas[0].id;
            });
          }
        }
      });
    }
  }

  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.img = base64;
        this.data.imagen = base64;
        this.appref.tick();
      });
    });
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/evt-eventos-imagen-del', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }

  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  Borrar() {
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar evento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/evt-eventos-del', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      });
    });
  }
  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/eventos')).then();
  }
  Guardar() {
    if ($('#txtFechaEvento input').val() != '') {
      this.data.fecha_evento = moment($('#txtFechaEvento').datepicker('getDate')).format('YYYY-MM-DD');
    } else {this.data.fecha_evento = null;}
    if ($('#txtFechaFinalizacion input').val() != '') {
      this.data.fecha_finalizacion = moment($('#txtFechaFinalizacion').datepicker('getDate')).format('YYYY-MM-DD');
    } else {this.data.fecha_finalizacion = null;}
    this.data.descripcion = tinymce.EditorManager.get('txtdescripcion').getContent();

    if (
         !this.data.titulo
      || !this.data.descripcion
      || !this.data.fecha_evento
      || this.data.titulo == ''
      || this.data.descripcion == ''
      || this.data.fecha_evento == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/evt-eventos-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.id = data.value;
        if (this.globals.me.tarifa == 'spg-event-planner-advanced') {
          if ((this.globals.me.puede_notificar || [0,1].includes(this.globals.me.id_rol))) {
            this.NotificarSuscriptores();
          }
        } else {
          this.Cancelar();
        }
      }, error => {
        $.buttoncancelloading('.btn-guardar');
        swal('Error', 'Se ha producido un error', 'error')
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/evt-eventos-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
        swal('Error', 'Se ha producido un error', 'error')
      });
    }
  }

  AbrirMapa() {
    $('#modal-mapa').modal('show');
    $('#mapa-container').html('<div id="mapa" style="width:100%;height:400px;"></div>');
    $('#mapa').removeAttr('class');
    setTimeout(() => {
      let point = [40.036198, -6.088190];
      $.localizaciontemp = point[0]+','+point[1];
      // let m = L.DomUtil.get('mapa'); if (m != null) { m._leaflet_id = null; }
      var mymap = L.map('mapa').setView(point, 14);
      if (this.data.localizacion) {
        point = [this.data.localizacion.split(',')[0], this.data.localizacion.split(',')[1]];
        $.localizaciontemp = point[0]+','+point[1];
        mymap.setView(point, 14);
      }
      L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      }).addTo(mymap);
      var marker = L.marker(point).addTo(mymap);
      mymap.on('click', function(e) {
        marker.setLatLng([e.latlng.lat, e.latlng.lng]);
        $.localizaciontemp = e.latlng.lat+','+e.latlng.lng;
      });
    }, 500);
  }
  SelecLocalizacion() {
    if ($.localizaciontemp) this.data.localizacion_maps = $.localizaciontemp;
    $('#modal-mapa').modal('hide');
  }

  NotificarSuscriptores() {
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Enviar notificación?",
      text: `Se notificará a todos los suscritos a "${$('#cmb-categorias option:selected').text()}"`,
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-info",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      $.buttonloading('btn-notificar-suscriptores', 'Enviando');
      this.globals.Api('/evt-eventos-notificar-suscriptores', this.data).subscribe(data => {
        $.buttoncancelloading('btn-notificar-suscriptores');
        if (!data || data.error) {
          if (data.value == 'Sin suscripciones') {
            toastr.info('No hay suscriptores a esta categoría', 'Sin suscripciones');
            return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Enviado', 'Notificación enviada correctamente', 'success');
      }, error => {
        $.buttoncancelloading('btn-notificar-suscriptores');
        swal('Error', 'Se ha producido un error', 'error')
      });
    });
  }

  Add(tipo:string = 'categoria') {
    let that = this;
    switch(tipo) {
      case 'categoria': {
        swal({
          title: "Nueva categoría",
          type: "input",
          showCancelButton: true,
          confirmButtonClass: "btn-primary",
          confirmButtonText: "Guardar",
          cancelButtonText: "Cancelar",
          inputPlaceholder: "Nombre de la categoría"
        }, function(inputValue) {
          if (inputValue === false) return false;
          if (inputValue === "") {
            swal.showInputError('Escribe el nombre');
            return false;
          }
          that.globals.Api('/evt-categorias-add', {categoria: inputValue}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Se ha producido un error', 'error');return;
            }
            that.data.id_categoria = data.value;
            that.CargarCategorias();
          }, error => {
            swal('Error', 'Se ha producido un error', 'error');return;
          });
          return false;
        });
      } break;
      case 'organizador': {
        swal({
          title: "Nuevo organizador",
          type: "input",
          showCancelButton: true,
          confirmButtonClass: "btn-primary",
          confirmButtonText: "Guardar",
          cancelButtonText: "Cancelar",
          inputPlaceholder: "Nombre del organizador"
        }, function(inputValue) {
          if (inputValue === false) return false;
          if (inputValue === "") {
            swal.showInputError('Escribe el nombre');
            return false;
          }
          that.globals.Api('/evt-organizadores-add', {nombre: inputValue}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Se ha producido un error', 'error');return;
            }
            that.data.id_organizador = data.value;
            that.CargarOrganizadores();
          }, error => {
            swal('Error', 'Se ha producido un error', 'error');return;
          });
          return false;
        });
      } break;
      case 'espacio': {
        swal({
          title: "Nuevo espacio",
          type: "input",
          showCancelButton: true,
          confirmButtonClass: "btn-primary",
          confirmButtonText: "Guardar",
          cancelButtonText: "Cancelar",
          inputPlaceholder: "Nombre del espacio"
        }, function(inputValue) {
          if (inputValue === false) return false;
          if (inputValue === "") {
            swal.showInputError('Escribe el nombre');
            return false;
          }
          that.globals.Api('/evt-espacios-add', {espacio: inputValue}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Se ha producido un error', 'error');return;
            }
            that.data.id_espacio = data.value;
            that.CargarEspacios();
          }, error => {
            swal('Error', 'Se ha producido un error', 'error');return;
          });
          return false;
        });
      } break;
      case 'sala': {
        if (!this.data.id_espacio || this.data.id_espacio == '') {
          swal('Seleccione espacio', 'Selecciona un espacio para añadir sala', 'error');return;
        }
        swal({
          title: "Nueva sala",
          type: "input",
          showCancelButton: true,
          confirmButtonClass: "btn-primary",
          confirmButtonText: "Guardar",
          cancelButtonText: "Cancelar",
          inputPlaceholder: "Nombre de la sala"
        }, function(inputValue) {
          if (inputValue === false) return false;
          if (inputValue === "") {
            swal.showInputError('Escribe el nombre');
            return false;
          }
          that.globals.Api('/evt-sala-add', {id_espacio: that.data.id_espacio, sala: inputValue}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Se ha producido un error', 'error');return;
            }
            that.data.id_sala = data.value;
            that.CargarSalas();
          }, error => {
            swal('Error', 'Se ha producido un error', 'error');return;
          });
          return false;
        });
      } break;
    }
  }
}
