import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarioComponent } from './backend/calendario/calendario.component';
import { CategoriasComponent } from './backend/categorias/categorias.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { EspaciosReservasComponent } from './backend/espacios-reservas/espacios-reservas.component';
import { EspaciosComponent } from './backend/espacios/espacios.component';
import { EditEventoComponent } from './backend/eventos/edit-evento/edit-evento.component';
import { EventosComponent } from './backend/eventos/eventos.component';
import { LoginComponent } from './backend/login/login.component';
import { MensajesComponent } from './backend/mensajes/mensajes.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { ReservasRestauranteComponent } from './backend/reservas-restaurante/reservas-restaurante.component';
import { SugerenciasComponent } from './backend/sugerencias/sugerencias.component';
import { ClientesSpaComponent } from './backend/superadmin/clientes-spa/clientes-spa.component';
import { EditClienteSpaComponent } from './backend/superadmin/clientes-spa/edit-cliente-spa/edit-cliente-spa.component';
import { SuscriptoresComponent } from './backend/suscriptores/suscriptores.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { ConfigSuscripcionComponent } from './frontend/config-suscripcion/config-suscripcion.component';
import { SugerenciasFrontComponent } from './frontend/sugerencias-front/sugerencias-front.component';
import { OrganizadoresComponent } from './backend/organizadores/organizadores.component';

const routes: Routes = [
  //BACKEND
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'admincontrol', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'config-app', component: ConfigAppComponent},
  {path: 'configuracion', component: ConfiguracionComponent},
  {path: 'mi-perfil', component: MiPerfilComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'usuarios/add', component: EditUsuarioComponent},
  {path: 'usuarios/edit', component: EditUsuarioComponent},
  {path: 'clientes', component: UsuariosComponent},
  {path: 'clientes/add', component: EditUsuarioComponent},
  {path: 'clientes/edit', component: EditUsuarioComponent},
  {path: 'clientes-spa', component: ClientesSpaComponent},
  {path: 'clientes-spa/add', component: EditClienteSpaComponent},
  {path: 'clientes-spa/edit', component: EditClienteSpaComponent},
  {path: 'calendario', component: CalendarioComponent},
  {path: 'eventos', component: EventosComponent},
  {path: 'eventos/add', component: EditEventoComponent},
  {path: 'eventos/edit', component: EditEventoComponent},
  {path: 'espacios', component: EspaciosComponent},
  {path: 'categorias', component: CategoriasComponent},
  {path: 'organizadores', component: OrganizadoresComponent},
  {path: 'dinamizadores', component: UsuariosComponent},
  {path: 'dinamizadores/add', component: EditUsuarioComponent},
  {path: 'dinamizadores/edit', component: EditUsuarioComponent},
  {path: 'sugerencias', component: SugerenciasComponent},
  {path: 'reservas-restaurante', component: ReservasRestauranteComponent},
  {path: 'espacios-reservas', component: EspaciosReservasComponent},
  {path: 'suscriptores', component: SuscriptoresComponent},
  {path: 'mensajes', component: MensajesComponent},
  //FRONEND
  {path: 'sugerencias-semanales', component: SugerenciasFrontComponent},
  {path: 'suscripcion-preferencias', component: ConfigSuscripcionComponent},
  {path: 'suscripcion-cancelar', component: ConfigSuscripcionComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
