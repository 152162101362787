<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nuevo espacio</a>
                        </div>
                    </div>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid block full break-padding" *ngIf="data && data.length">
                    <!--
                    Available Table Classes:
                        'table'             - basic table
                        'table-bordered'    - table with full borders
                        'table-borderless'  - table with no borders
                        'table-striped'     - striped table
                        'table-condensed'   - table with smaller top and bottom cell padding
                        'table-hover'       - rows highlighted on mouse hover
                        'table-vcenter'     - middle align content vertically
                    -->
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                                <th order="espacio">Espacio</th>
                                <th style="width:200px" order="email">Email</th>
                                <th order="dinamizador">Dinamizador</th>
                                <th style="width:50px">Editar</th>
                                <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data" class="cursor">
                                <td class="font-bold text-primary" (click)="Editar(item)">
                                  <div>{{item.espacio}}</div>
                                </td>
                                <td><span *ngIf="item.email" class="no-wrap"><i class="fa fa-envelope"></i> {{item.email}}</span></td>
                                <td class="breaktd">{{item.dinamizador}}</td>
                                <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>
                <div class="no-resultados" *ngIf="!data || !data.length">
                    <div>Aún no se han creado espacios. Crea tu primera espacio:</div>
                    <a class="btn btn-primary" (click)="Nuevo()"><i class="fa fa-plus"></i> Añadir espacio</a>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>

<div class="modal fade" id="modal-crear-espacio" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Nuevo espacio</h4>
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Nombre <span class="text-danger">*</span></label>
                        <div class="col-lg-9">
                            <input type="text" class="form-control" [(ngModel)]="dataitem.espacio" maxlength="50">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Descripción</label>
                        <div class="col-lg-9">
                            <textarea id="txtdescripcion" type="text" class="form-control" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="dataitem.descripcion" maxlength="5000" rows="4"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Dirección</label>
                        <div class="col-lg-9">
                            <input type="text" class="form-control" [(ngModel)]="dataitem.direccion" maxlength="200">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Teléfono</label>
                        <div class="col-lg-9">
                            <input type="tel" class="form-control" [(ngModel)]="dataitem.telefono" maxlength="20">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Email</label>
                        <div class="col-lg-9">
                            <input type="email" class="form-control" [(ngModel)]="dataitem.email" maxlength="80">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Web</label>
                        <div class="col-lg-9">
                            <input type="url" class="form-control" [(ngModel)]="dataitem.url_web" maxlength="200">
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-12">
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Imagen</label>
                        <div class="col-lg-9">
                            <div *ngIf="!dataitem.imagen && !dataitem.img" class="cursor text-center m-t-10" (click)="SelecImagen()">
                                <img src="assets/subir-imagen.png" style="height:80px">
                            </div>
                            <div *ngIf="dataitem.imagen && !dataitem.img">
                                <a class="img-preview-container cursor" title="Imagen espacio">
                                    <img [src]="dataitem.imagen" style="max-height:125px;">
                                </a>
                                <div class="text-center">
                                    <a class="btn btn-sm btn-default" (click)="SelecImagen()">Cambiar imagen</a>
                                    <a class="btn btn-sm btn-default m-l-10" (click)="DelImagen()"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                            <div *ngIf="dataitem.img" class="img-preview-container cursor" (click)="SelecImagen()">
                                <img [src]="dataitem.img" style="max-height:125px;">
                            </div>    
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Aforo personas</label>
                        <div class="col-lg-9">
                            <input type="number" class="form-control" [(ngModel)]="dataitem.aforo" maxlength="12">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Localización Maps</label>
                        <div class="col-lg-9">
                            <div class="input-group m-b width-100">
                                <input type="text" placeholder="Seleccione una localización" class="form-control" [(ngModel)]="dataitem.localizacion_maps">
                                <div class="input-group-append cursor" (click)="AbrirMapa()">
                                    <span class="input-group-addon bg-exman-oscuro" style="font-size:20px;padding-top:6px;"><i class="fa fa-map-marker"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Salas <span class="text-primary" *ngIf="dataitem && dataitem.editandosalas">*</span></label>
                        <div class="col-lg-9">
                            <div class="input-group m-b width-100">
                                <input id="txtSala" type="text" placeholder="Añadir sala" class="form-control" [(ngModel)]="dataitem.sala">
                                <div class="input-group-append cursor" (click)="AddSala()">
                                    <span class="input-group-addon bg-exman-oscuro" style="font-size:20px;padding-top:6px;"><i class="fa fa-plus"></i></span>
                                </div>
                            </div>
                            <div class="table-responsive table-grid ibox-content padding-0">
                                <table class="table table-hover">
                                    <thead>
                                    <tr>
                                        <th order="sala">Sala</th>
                                        <th order="dinamizador">Dinamizador</th>
                                        <th style="width:50px">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of salas" class="cursor">
                                        <td class="font-bold text-primary">
                                            <div contenteditable (blur)="EditarSala(item, $event)">{{item.sala}}</div>
                                        </td>
                                        <td>
                                            <select id="cmbDinamizadorSala" data-placeholder="Seleccione dinamizador ..." class="form-control chosen-select" 
                                                [(ngModel)]="item.id_usuario"
                                                (change)="ChangeDinamizadorSala(item, $event)">
                                                <option value="">- Sin establecer -</option>
                                                <option *ngFor="let item of usuarios" [value]="item.id">{{item.empresa}}</option>
                                            </select>
                                        </td>
                                        <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="BorrarSala(item)"><i class="fa fa-trash text-white"></i></a></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label">Dinamizador</label>
                        <div class="col-lg-9">
                            <select id="cmbDinamizador" data-placeholder="Seleccione dinamizador ..." class="form-control chosen-select" [(ngModel)]="data.id_usuario">
                                <option value="">- Sin establecer -</option>
                                <option *ngFor="let item of usuarios" [value]="item.id">{{item.empresa}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarModal" type="button" class="btn btn-primary" (click)="GuardarModal()">Guardar</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-mapa" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" style="font-size: 3rem;"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
                <h4 class="modal-title">Seleccione localizaci&oacute;n</h4>
                <small></small>
            </div>
            <div id="mapa-container" class="modal-body" style="padding: 0;">
                <div id="mapa" style="width:100%;height:400px;"></div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="margin-right: auto;" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="SelecLocalizacion()">Seleccionar</button>
            </div>
        </div>
    </div>
</div>