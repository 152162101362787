<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">{{title}} </h1>
                                <button class="btn btn-xs btn-default middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>{{title}}</li>
                            </ul>
                            <div style="display:flex;flex-wrap:wrap;gap:1rem;width:100%;padding-bottom:10px;">
                              <div class="badge bg-secondary font-14" *ngIf="params.total">{{params.total}} total</div>
                              <div class="font-12" *ngIf="datageneral.total_suscriptores_email"><i class="fa fa-envelope text-warning"></i> {{datageneral.total_suscriptores_email}} total</div>
                              <div class="font-12" *ngIf="datageneral.total_suscriptores_whatsapp"><i class="fa fa-whatsapp text-success"></i> {{datageneral.total_suscriptores_whatsapp}} total</div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid block full break-padding" *ngIf="data && data.length">
                    <!--
                    Available Table Classes:
                        'table'             - basic table
                        'table-bordered'    - table with full borders
                        'table-borderless'  - table with no borders
                        'table-striped'     - striped table
                        'table-condensed'   - table with smaller top and bottom cell padding
                        'table-hover'       - rows highlighted on mouse hover
                        'table-vcenter'     - middle align content vertically
                    -->
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                                <th>Suscriptor</th>
                                <th style="width:120px" order="tipo_notificacion">Tipo</th>
                                <th style="width:100px" order="alta" >Alta</th>
                                <th order="categorias">Suscrito a</th>
                                <th style="width:60px">Perm. Notif.</th>
                                <!-- <th style="width:50px">Editar</th> -->
                                <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data" class="cursor">
                                <td>
                                  <div class="font-bold text-primary" *ngIf="item.nombre">{{item.nombre}}</div>
                                  <div *ngIf="item.email">
                                    <a class="mailto:{{item.email}}" class="breakword"><i class="fa fa-envelope-o"></i> {{item.email}}</a>
                                  </div>
                                  <div *ngIf="item.telefono">
                                    <a class="tel:{{item.telefono}}" class="breakword"><i class="fa fa-phone"></i> {{item.telefono}}</a>
                                  </div>
                                </td>
                                <td>
                                  <div class="font-bold text-warning" *ngIf="item.tipo_notificacion == 'email'"><i class="fa fa-envelope-o"></i> Email</div>
                                  <div class="font-bold text-success" *ngIf="item.tipo_notificacion == 'whatsapp'"><i class="fa fa-whatsapp"></i> WhatsApp</div>
                                  <div class="font-bold text-info" *ngIf="item.tipo_notificacion == 'ambos'"><i class="fa fa-envelope-o"></i>&nbsp;<i class="fa fa-whatsapp"></i> Todo</div>
                                </td>
                                <td>{{item.alta_format}}</td>
                                <td>{{item.categorias}}</td>
                                <td class="text-center cursor btntd" (click)="CambiarPermiso(item)">
                                  <a *ngIf="item.permitir_notificaciones"><i class="fa fa-check text-success"></i></a>
                                  <a *ngIf="!item.permitir_notificaciones"><i class="fa fa-close text-danger"></i></a>
                                </td>
                                <!-- <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td> -->
                                <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>
                <div class="no-resultados" *ngIf="!data || !data.length">
                    <div>Aún no hay suscriptores</div>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>