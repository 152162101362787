<!-- In the PHP version you can set the following options from inc/config file -->
<!--
    Available header.navbar classes:

    'navbar-default'            for the default light header
    'navbar-inverse'            for an alternative dark header

    'navbar-fixed-top'          for a top fixed header (fixed main sidebar with scroll will be auto initialized, functionality can be found in js/app.js - handleSidebar())
        'header-fixed-top'      has to be added on #page-container only if the class 'navbar-fixed-top' was added

    'navbar-fixed-bottom'       for a bottom fixed header (fixed main sidebar with scroll will be auto initialized, functionality can be found in js/app.js - handleSidebar()))
        'header-fixed-bottom'   has to be added on #page-container only if the class 'navbar-fixed-bottom' was added
-->
<header class="navbar navbar-inverse navbar-fixed-top" *ngIf="globals && globals.me && globals.me.id_rol == 0">
    <!-- Left Header Navigation -->
    <ul class="nav navbar-nav-custom">
        <!-- Main Sidebar Toggle Button -->
        <li>
            <a href="javascript:void(0)" onclick="App.sidebar('toggle-sidebar');this.blur();">
                <i class="fa fa-ellipsis-v fa-fw animation-fadeInRight" id="sidebar-toggle-mini"></i>
                <i class="fa fa-bars fa-fw animation-fadeInRight" id="sidebar-toggle-full"></i>
            </a>
        </li>
        <!-- END Main Sidebar Toggle Button -->
        <li>
            <a [routerLink]="['/dashboard']">
                <i class="gi gi-home" style="font-size:14px"></i>
            </a>
        </li>

        <ng-container *ngIf="globals.cliente">
            <li *ngIf="globals.cliente.tarifa == 'spg-event-planner-advanced' && globals.cmensajeswhatsapp">
                <a [routerLink]="['/mensajes']">
                    <i class="fa fa-bell"></i>
                    <span class="badge badge-whatsapp-sm">{{globals.cmensajeswhatsapp}}</span>
                </a>
            </li>
            <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" style="background-color: var(--color-primary);">
                    <i class="fa fa-plus"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-left font-16">
                    <li>
                        <a [routerLink]="['/eventos/add']" class="cursor"><i class="fa fa-plus"></i> Crear evento</a>
                    </li>
                    <li>
                        <a [routerLink]="['/espacios']" class="cursor"><i class="fa fa-plus"></i> Crear espacio</a>
                    </li>
                    <li>
                        <a [routerLink]="['/categorias']" class="cursor"><i class="fa fa-plus"></i> Crear categoría</a>
                    </li>
                    <li>
                        <a [routerLink]="['/dinamizadores/add']" class="cursor"><i class="fa fa-plus"></i> Crear dinamizador</a>
                    </li>
                </ul>
            </li>
        </ng-container>

        <!-- Header Link -->
        <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/dashboard']"><strong>Bienvenid@</strong> al gestor de eventos</a>
        </li>
        <!-- END Header Link -->
    </ul>
    <!-- END Left Header Navigation -->

    <!-- Right Header Navigation -->
    <ul class="nav navbar-nav-custom pull-right">
        <!-- Search Form - ->
        <li>
            <form action="page_ready_search_results.html" method="post" class="navbar-form-custom">
                <input type="text" id="top-search" name="top-search" class="form-control" placeholder="Search..">
            </form>
        </li>
        <!- - END Search Form
        -->
        <li *ngIf="globals.cliente" class="btn-cliente-activo">
            <a [routerLink]="['/eventos']"><i class="icofont-ui-user-group"></i> {{globals.cliente.empresa}}</a>
            <div class="btn-cerrar cursor" (click)="CerrarVistaCliente()"><i class="fa fa-times"></i></div>
        </li>

        <!-- User Dropdown -->
        <li class="dropdown">
            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                <ng-container *ngIf="globals && globals.me && globals.me.imagen && globals.me.imagen != ''">
                    <img src="{{globals.me.imagen}}" alt="avatar" onerror="this.src = 'assets/icon-user.svg'">
                </ng-container>
                <ng-container *ngIf="!globals || !globals.me || !globals.me.imagen || globals.me.imagen == ''">
                    <img src="assets/icon-user.svg" alt="avatar">
                </ng-container>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-header">
                    <strong class="text-uppercase">{{globals.me.rol}}</strong>
                </li>
                <li *ngIf="globals.me && globals.me.id_rol == 0">
                    <a [routerLink]="['/config-app']" class="cursor">
                        <i class="icofont-settings sidebar-nav-icon fa-fw pull-right"></i>
                        Configuración
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/mi-perfil']" class="cursor">
                        <i class="fa fa-pencil-square fa-fw pull-right"></i>
                        Mi perfil
                    </a>
                </li>
                <li>
                    <a (click)="Logout()" class="cursor">
                        <i class="fa fa-power-off fa-fw pull-right"></i>
                        Cerrar sesión
                    </a>
                </li>
            </ul>
        </li>
        <!-- END User Dropdown -->
    </ul>
    <!-- END Right Header Navigation -->
</header>
<header class="navbar navbar-inverse navbar-fixed-top" *ngIf="globals && globals.me && globals.me.id_rol != 0">
    <!-- Left Header Navigation -->
    <ul class="nav navbar-nav-custom">
        <!-- Main Sidebar Toggle Button -->
        <li>
            <a href="javascript:void(0)" onclick="App.sidebar('toggle-sidebar');this.blur();">
                <i class="fa fa-ellipsis-v fa-fw animation-fadeInRight" id="sidebar-toggle-mini"></i>
                <i class="fa fa-bars fa-fw animation-fadeInRight" id="sidebar-toggle-full"></i>
            </a>
        </li>
        <!-- END Main Sidebar Toggle Button -->
        <li>
            <a [routerLink]="['/dashboard']">
                <i class="gi gi-home" style="font-size:14px"></i>
            </a>
        </li>

        <!-- Header Link -->
        <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1">
            <li *ngIf="globals.me.tarifa == 'spg-event-planner-advanced' && globals.cmensajeswhatsapp">
                <a [routerLink]="['/mensajes']">
                    <i class="fa fa-bell"></i>
                    <span class="badge badge-whatsapp-sm">{{globals.cmensajeswhatsapp}}</span>
                </a>
            </li>
            <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" style="background-color: var(--color-primary);">
                    <i class="fa fa-plus"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-left font-16">
                    <li>
                        <a [routerLink]="['/eventos/add']" class="cursor"><i class="fa fa-plus"></i> Crear evento</a>
                    </li>
                    <li>
                        <a [routerLink]="['/espacios']" class="cursor"><i class="fa fa-plus"></i> Crear espacio</a>
                    </li>
                    <li>
                        <a [routerLink]="['/categorias']" class="cursor"><i class="fa fa-plus"></i> Crear categoría</a>
                    </li>
                    <li>
                        <a [routerLink]="['/dinamizadores/add']" class="cursor"><i class="fa fa-plus"></i> Crear dinamizador</a>
                    </li>
                </ul>
            </li>
        </ng-container>
        <ng-container *ngIf="globals.me.id_rol == 2">
            <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" style="background-color: var(--color-primary);">
                    <i class="fa fa-plus"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-left font-16">
                    <li>
                        <a [routerLink]="['/eventos/add']" class="cursor"><i class="fa fa-plus"></i> Crear evento</a>
                    </li>
                </ul>
            </li>
        </ng-container>
        <ng-container *ngIf="globals.me.id_rol == 4">
            <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" style="background-color: var(--color-primary);">
                    <i class="fa fa-plus"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-left font-16">
                    <li>
                        <a [routerLink]="['/reservas-restaurante']" [queryParams]="{v: 'add'}" class="cursor"><i class="fa fa-plus"></i> Crear reserva</a>
                    </li>
                </ul>
            </li>
        </ng-container>
        <!-- END Header Link -->
    </ul>
    <!-- END Left Header Navigation -->

    <!-- Right Header Navigation -->
    <ul class="nav navbar-nav-custom pull-right">
        <!-- Search Form - ->
        <li>
            <form action="page_ready_search_results.html" method="post" class="navbar-form-custom">
                <input type="text" id="top-search" name="top-search" class="form-control" placeholder="Search..">
            </form>
        </li>
        <!- - END Search Form-->

        <!-- User Dropdown -->
        <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/dashboard']"><strong>Bienvenid@</strong> al gestor de eventos</a>
        </li>
        <li class="dropdown">
            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                <ng-container *ngIf="globals && globals.me && globals.me.imagen && globals.me.imagen != ''">
                    <img src="{{globals.me.imagen}}" alt="avatar" onerror="this.src = 'assets/icon-user.svg'">
                </ng-container>
                <ng-container *ngIf="!globals || !globals.me || !globals.me.imagen || globals.me.imagen == ''">
                    <img src="assets/icon-user.svg" alt="avatar">
                </ng-container>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-header">
                    <strong class="text-uppercase">{{globals.me.rol}}</strong>
                </li>
                <li>
                    <a [routerLink]="['/mi-perfil']" class="cursor">
                        <i class="fa fa-pencil-square fa-fw pull-right"></i>
                        Mi perfil
                    </a>
                </li>
                <li>
                    <a (click)="Logout()" class="cursor">
                        <i class="fa fa-power-off fa-fw pull-right"></i>
                        Cerrar sesión
                    </a>
                </li>
            </ul>
        </li>
        <!-- END User Dropdown -->
    </ul>
    <!-- END Right Header Navigation -->
</header>