<ng-container *ngIf="!vista || vista == '' || vista == 'defecto'">
    <a class="sugerencias noselect" href="{{globals.config.url_cliente}}/carta" target="_parent">
        <h2>{{titulo}}</h2>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let item of sugerencias">
                <div class="sugerencias-item">
                    <img [src]="item.imagen" [alt]="item.titulo">
                    <div class="body">
                        <h4>{{item.titulo}}</h4>
                        <span class="descripcion">{{item.descripcion}}</span>
                        <div class="precio" *ngIf="item.precio_format">{{item.precio_format}}€ <span *ngIf="item.precio_sufijo">{{item.precio_sufijo}}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</ng-container>
<ng-container *ngIf="vista == 'slider'">
    <div class="sugerencias noselect vista-{{vista}}">
        <div class="swiper-container swiper-sugerencias">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of sugerencias;let i = index">
                    <div class="sugerencias-item">
                        <div class="imagen">
                            <img [src]="item.imagen" [alt]="item.titulo">
                        </div>
                        <div class="body">
                            <h4>{{item.titulo}}</h4>
                            <span class="descripcion">{{item.descripcion}}</span>
                            <div class="precio" *ngIf="item.precio_format">{{item.precio_format}}€ <span *ngIf="item.precio_sufijo">{{item.precio_sufijo}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="vista == 'scroll'">
    <div class="sugerencias noselect vista-{{vista}}">
        <div class="sugerencias-item" *ngFor="let item of sugerencias;let i = index">
            <div class="imagen">
                <img [src]="item.imagen" [alt]="item.titulo">
            </div>
            <div class="body">
                <h4>{{item.titulo}}</h4>
                <span class="descripcion">{{item.descripcion}}</span>
                <div class="precio" *ngIf="item.precio_format">{{item.precio_format}}€ <span *ngIf="item.precio_sufijo">{{item.precio_sufijo}}</span></div>
            </div>
        </div>
    </div>
</ng-container>