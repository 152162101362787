<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header m-b-0">
                    <div class="row-equal-cols">
                        <div class="col-lg-7 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <div>
                                  <a [href]="data.url" target="_blank" class="text-primary" *ngIf="data && data.url">{{data.url}} <i class="fa fa-external-link"></i></a>
                                </div>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/eventos']">Eventos</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-danger m-r-60 text-white" (click)="Borrar()" *ngIf="data && data.id && data.id != ''">Borrar</a>
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                          <ng-container *ngIf="data.id && data.id != '' && globals.me.tarifa == 'spg-event-planner-advanced' && (globals.me.puede_notificar || [0,1].includes(globals.me.id_rol))">
                            <a id="btn-notificar-suscriptores" class="btn btn-success m-l-20" (click)="NotificarSuscriptores()"><i class="fa fa-bell-o"></i> Notif. Suscriptores</a>
                          </ng-container>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">


                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                        <i class="icofont-camera-alt text-primary" style="font-size:60px;"></i>
                      </div>
                      <div class="img-preview-container cursor relative" (click)="SelecImagen()" *ngIf="data.imagen && data.imagen != ''">
                        <img [src]="data.imagen">
                        <button class="btn btn-danger btn-sm absolute" (click)="BorrarImagen($event)" style="top:-1px;right:0"><i class="fa fa-trash"></i></button>
                      </div>
                      <div class="form-group m-t-10">
                        <label>Imagen desde url externa</label>
                        <input id="txturlexterna" type="url" class="form-control" [(ngModel)]="data.img_externa" maxlength="400" placeholder="Pega aquí la url o la imagen">
                        <img id="img-externa" src="" alt="" class="hidden">
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>Fecha <span class="text-danger">*</span></label>
                            <div id="txtFechaEvento" class="input-group date" style="width:100%;">
                              <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                              <input type="text" class="form-control" autocomplete="off" [(ngModel)]="data.fecha_format">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>Fecha finalización</label>
                            <div id="txtFechaFinalizacion" class="input-group date" style="width:100%;">
                              <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                              <input type="text" class="form-control" autocomplete="off" [(ngModel)]="data.fecha_finalizacion_format">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-xs-12">
                          <div class="form-group">
                            <label>Hora inicio</label>
                            <input type="time" class="form-control" [(ngModel)]="data.hora_inicio" maxlength="5" step="300">
                          </div>
                        </div>
                        <div class="col-md-6 col-xs-12">
                          <div class="form-group">
                            <label>Hora fin</label>
                            <input type="time" class="form-control" [(ngModel)]="data.hora_fin" maxlength="5" step="300">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Categor&iacute;a <span class="text-danger">*</span></label>
                        <a class="btn btn-sm btn-default pull-right m-t--5" (click)="Add('categoria')"><i class="fa fa-plus"></i> Añadir</a>
                        <select class="form-control" id="cmb-categorias" [(ngModel)]="data.id_categoria">
                          <option *ngFor="let item of categorias" [value]="item.id">{{item.categoria}}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label>Organizador</label>
                        <a class="btn btn-sm btn-default pull-right m-t--5" (click)="Add('organizador')"><i class="fa fa-plus"></i> Añadir</a>
                        <select class="form-control" id="cmb-categorias" [(ngModel)]="data.id_organizador">
                          <option value="">-- Sin seleccionar --</option>
                          <option *ngFor="let item of organizadores" [value]="item.id">{{item.nombre}}</option>
                        </select>
                      </div>
                      <ng-container *ngIf="data.id && data.id != '' && globals.me.tarifa == 'spg-event-planner-advanced'">
                        <br>
                        <div class="block m-b-20" style="padding-bottom:20px">
                          <div class="block-title"><h4 style="font-weight:normal;font-size:14px">Exportar html para correo</h4></div>
                          <a class="btn btn-default btn-sm" (click)="globals.ExportarEventoHTMLConCabecera(data)"><i class="fa fa-code"></i> Exportar completo</a><br>
                          <a class="btn btn-default btn-sm m-t-10" (click)="globals.ExportarEventoHTMLSimple(data)"><i class="fa fa-code"></i> Exportar simple</a><br>
                          <a class="btn btn-default btn-sm m-t-10" (click)="globals.ExportarHTMLCabecera()"><i class="fa fa-code"></i> Exportar cabecera email</a><br>
                          <a class="btn btn-default btn-sm m-t-10" (click)="globals.ExportarHTMLPie()"><i class="fa fa-code"></i> Exportar pie email</a><br>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">

                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>Titulo <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="data.titulo" maxlength="80">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>Descripci&oacute;n <span class="text-danger">*</span></label>
                            <textarea id="txtdescripcion" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.descripcion" maxlength="5000" rows="6"></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4 col-xs-12">
                          <div class="form-group">
                            <label>Espacio <span class="text-danger">*</span></label>
                            <a class="btn btn-sm btn-default pull-right m-t--5" (click)="Add('espacio')"><i class="fa fa-plus"></i> Añadir</a>
                            <select class="form-control" [(ngModel)]="data.id_espacio" (change)="ChangeEspacio()">
                              <option *ngFor="let item of espacios" [value]="item.id">{{item.espacio}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 col-xs-12">
                          <div class="form-group">
                            <label>Sala</label>
                            <a class="btn btn-sm btn-default pull-right m-t--5" (click)="Add('sala')"><i class="fa fa-plus"></i> Añadir</a>
                            <select class="form-control" [(ngModel)]="data.id_sala">
                              <option value="">-- Sin seleccionar --</option>
                              <option *ngFor="let item of salas" [value]="item.id">{{item.sala}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 col-xs-12">
                          <div class="form-group">
                            <label>Localidad</label>
                            <input type="text" class="form-control" list="localidades" [(ngModel)]="data.localidad" maxlength="100">
                            <datalist id="localidades">
                              <option *ngFor="let item of localidades" [value]="item.localidad+', '+item.provincia"></option>
                            </datalist>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-xs-12">
                          <div class="form-group">
                            <label>Lugar</label>
                            <input type="text" class="form-control" [(ngModel)]="data.lugar" maxlength="100">
                          </div>
                        </div>
                        <div class="col-md-6 col-xs-12">
                          <div class="form-group">
                            <label>Localizaci&oacute;n MAPS</label>
                            <div class="input-group width-100 m-b">
                                <input type="text" placeholder="Coordenadas GPS" class="form-control" [(ngModel)]="data.localizacion_maps">
                                <div class="input-group-append cursor" (click)="AbrirMapa()">
                                    <span class="input-group-addon bg-exman-oscuro" style="font-size:20px;padding-top:6px;"><i class="fa fa-map-marker"></i></span>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-xs-12">
                          <!-- <div class="form-group">
                            <label>Aforo</label>
                            <input type="number" class="form-control" [(ngModel)]="data.aforo" maxlength="20">
                          </div> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>URL más información</label>
                            <input type="url" class="form-control" [(ngModel)]="data.url_externa" maxlength="400">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          <div class="form-group">
                            <label>URL reserva</label>
                            <input type="url" class="form-control" [(ngModel)]="data.url_reserva" maxlength="400">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                    <!-- <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">Datos generales</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">

                        </div>
                    </div> -->
                </div>

                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-mapa" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" style="font-size: 3rem;"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
              <h4 class="modal-title">Seleccione localizaci&oacute;n</h4>
              <small></small>
          </div>
          <div id="mapa-container" class="modal-body" style="padding: 0;">
              <div id="mapa" style="width:100%;height:400px;"></div>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-white" style="margin-right: auto;" data-dismiss="modal">Cancelar</button>
              <button type="button" class="btn btn-primary" (click)="SelecLocalizacion()">Seleccionar</button>
          </div>
      </div>
  </div>
</div>