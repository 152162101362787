import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let FullCalendar: any;

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html'
})
export class EventosComponent implements OnInit {
  public eventos:any = [];
  public params:any = {
    total: 0,
    pages: 0,
    filtro: null,
    superadmin: true
  };
  public cargando:boolean = false;
  public separar_eventos:boolean = true;
  public eventos_permanentes:boolean = false;
  public colores:any = [];
  public categorias:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    this.CargarCalendario();
    this.globals.Api('/evt-categorias').subscribe(data => {
      if (!data || !data.length) return;
      this.categorias = data;
    });
    this.globals.Badges();
    $.EditarEvento = (id:any) => {
      if (!id || id == '') return;
      this.globals.Api('/evt-eventos', {id: id}).subscribe(data => {
        if (!data || !data.length) return;
        this.Editar(data[0]);
      });
    }
    if (this.globals.cliente) {
      this.globals.me.tarifa = this.globals.cliente.tarifa;
      this.globals.me.tarifa_nombre = this.globals.cliente.tarifa_nombre;
      this.separar_eventos = (this.globals.cliente.config.separar_eventos_permanentes == '1' ? true : false);
    } else {
      this.separar_eventos = (this.globals.me.separar_eventos_permanentes == '1' ? true : false);
    }
  }
  CargarCalendario() {
    if (this.globals.GetData('last-date')) globalThis.lastdate = this.globals.GetData('last-date');
    setTimeout(() => {
      globalThis.calendarEl = document.getElementById('calendar');
      globalThis.calendar = new FullCalendar.Calendar(globalThis.calendarEl, {
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'title',
          right: 'dayGridMonth,dayGridWeek today prev,next'
        },
        locale: 'es',
        height: 'auto',
        initialDate: (globalThis.lastdate ? globalThis.lastdate : null),
        dateClick: (info:any) => {
          $('.popover').popover('hide');
          let data = {
            fecha_format: moment(info.date).format('DD/MM/YYYY'),
            fecha_inicio_format: moment(info.date).format('DD/MM/YYYY'),
            localidad: ''
          };
          this.globals.passData = JSON.parse(JSON.stringify(data));
          this.ngZone.run(() => this.router.navigateByUrl('/eventos/add')).then();
          this.globals.SaveData(moment(info.date).format('YYYY-MM-DD'), 'last-date');
        },
        eventClick: (info:any) => {
          $('.popover').popover('hide');
          if (!info.event.extendedProps.data) return;
          let data = info.event.extendedProps.data;
          if (data.popover) {
            data.popover = false;
            return;
          }
          function stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
          }
          for (let item of globalThis.calendar.getEvents()) item.extendedProps.data.popover = false;
          data.popover = true;
          data.descripcion = this.globals.TruncateString(stripHtml(data.descripcion), 22);
          $(info.el).popover({ 
            html: true,
            title: `${data.titulo}`, 
            content: `
              <div class="popover-body">
                ${data.imagen ? `<img src="${data.imagen}" class="cursor" onclick="$.EditarEvento('${data.id}')">` : ''}
                <div class="categoria">${data.categoria}</div>
                ${data.organizador && data.organizador != '' ? `<div class="organizador"><i class="icofont-people text-primary"></i> ${data.organizador}</div>` : ''}
                ${data.localidad && data.localidad != '' ? `<div class="localidad"><i class="fa fa-map-marker text-primary"></i> ${data.localidad}</div>` : ''}
                <div class="fecha">${data.fecha_inicio_format && data.fecha_fin_format && data.fecha_inicio_format != data.fecha_fin_format ? `<i class="fa fa-calendar text-primary"></i> ${data.fecha_inicio_format} - ${data.fecha_fin_format}` : `<i class="fa fa-calendar text-primary"></i> ${data.fecha_inicio_format}`}</div>
                <div class="descripcion">${data.descripcion}</div>
              </div>
              <ul class="popover-btn">
                <li><a class="btn btn-primary" onclick="$.EditarEvento('${data.id}')"><i class="fa fa-pencil"></i> Editar evento</a></li>
                ${data.url && data.url != '' ? `
                <li><a class="btn btn-default" href="${data.url}" target="_blank"><i class="fa fa-eye"></i> Ver evento</a></li>
                ` : ''}
              </ul>
            `,
            trigger: 'focus',
            placement: 'auto',
            container: 'body'
          }).popover('show');
        },
        eventContent: (e:any) => {
          return {html: e.event.title};
        },
        datesSet: (dateInfo:any) => {
          $('.popover').popover('hide');
          $.LoadingOverlay("show", {
            background: 'rgba(255, 255, 255, 0.6)',
            imageResizeFactor: 0.5,
            text: 'Cargando datos ...'
          });
          globalThis.calendar.removeAllEvents();
          this.eventos = [];
          this.params.fecha_inicio = moment(dateInfo.start).format('YYYY-MM-DD');
          this.params.fecha_fin = moment(dateInfo.end).add(-1, 'day').format('YYYY-MM-DD');
          globalThis.lastdate = globalThis.calendar.currentData.currentDate;
          if (this.cargando) {
            $.LoadingOverlay("hide", true);
            return;
          }
          this.cargando = true;
          this.globals.Api('/evt-eventos', this.params).subscribe((data: any) => {
            $.LoadingOverlay("hide", true);
            this.cargando = false;
            globalThis.calendar.removeAllEvents();
            if (!data || !data.length) return;
            this.eventos = data;
            this.AddEvents(this.eventos);
          }, error => {
            $.LoadingOverlay("hide", true);
            this.cargando = false;
          });
          this.globals.SaveData(moment(dateInfo.view.currentStart).format('YYYY-MM-DD'), 'last-date');
        }
      });
      globalThis.calendar.render();

      
      $(".fc-toolbar-title").attr('id', 'fc-datepicker-header').before('<input size="1" style="height: 0px; width:0px; border: 0px;" id="fc-datepicker" value="" />');
      $("#fc-datepicker").datepicker({
        keyboardNavigation: false,
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        format: "MM yyyy",
        viewMode: "months", 
        minViewMode: "months",
        container: '.calendar-container'
      });
      $('#fc-datepicker').datepicker().on('changeDate', function (ev) {
        globalThis.calendar.gotoDate( ev.date );
        $("#fc-datepicker").datepicker('hide');
      });
      $("#fc-datepicker").focus(function () {
          $("#ui-datepicker-div").position({
            my: "center bottom",
            at: "center bottom",
            of: $(this)
          });
      });
      $("#fc-datepicker-header").css('cursor', 'pointer');
      $("#fc-datepicker-header").click(function() {
        $("#fc-datepicker").datepicker('show');
        $(".datepicker").css('margin-top', '40px');
      });
    }, 400);
  }
  AddEvents(events) {
    if (!events || !events.length) return;
    let eventsSource:any = [];
    for (let item of events) {
      if (this.separar_eventos) {
        if (this.eventos_permanentes && !item.larga_duracion) continue;
        if (!this.eventos_permanentes && item.larga_duracion) continue;
      }
      // if (item.titulo.length > 30) item.titulo = truncateString(item.titulo, 4); 
      // ${item.imagen ? `<img src="${item.imagen}">` : ''}
      // <span class="cliente">${item.categoria}</span><br>
      // ${item.fecha_inicio_format && item.fecha_fin_format && item.fecha_inicio_format != item.fecha_fin_format ? `<span class="fecha">${item.fecha_inicio_format} - ${item.fecha_fin_format}</span>` : ``}
      eventsSource.push({
        title: `
          <div class="titulo"><b>${item.titulo}</b></div>
          <div class="clearfix"></div>
        `,
        start: moment(item.fecha_inicio).format('YYYY-MM-DD'),
        end: (item.fecha_inicio == item.fecha_fin ? moment(item.fecha_fin).format('YYYY-MM-DD') : moment(item.fecha_fin).add(1,'day').format('YYYY-MM-DD')),
        // display: 'block',
        color: item.color_categoria,
        textColor: '#FFF',
        data: item,
        classNames: ['ev-general']
      });
    }
    globalThis.calendar.addEventSource(eventsSource);
  }
  GetEventosFiltrados() {
    if (!this.separar_eventos) return this.eventos;
    return this.eventos.filter(el => (this.eventos_permanentes && el.larga_duracion) || (!this.eventos_permanentes && !el.larga_duracion) );
  }
  MostrarOcultarPermanentes() {
    this.eventos_permanentes = !this.eventos_permanentes;
    globalThis.calendar.removeAllEvents();
    this.AddEvents(this.eventos);
    $('.popover').popover('hide');
  }
  Buscar() {
    if (this.params.filtro && this.params.filtro != '') {
      this.separar_eventos = false;
    } else {
      if (this.globals.cliente) {
        this.separar_eventos = (this.globals.cliente.config.separar_eventos_permanentes == '1' ? true : false);
      } else {
        this.separar_eventos = (this.globals.me.separar_eventos_permanentes == '1' ? true : false);
      }
    }
    $('.popover').popover('hide');
    this.params.page_index = 1;
    this.CargarCalendario();
  }
  FiltrarCategoria(id:any = null) {
    $('.popover').popover('hide');
    this.params.id_categoria = id;
    this.params.page_index = 1;
    this.CargarCalendario();
  }
  Nuevo() {
    $('.popover').popover('hide');
    if ($.NuevaReserva) $.NuevaReserva();
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/eventos/add')).then();
  }
  Editar(item:any) {
    $('.popover').popover('hide');
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/eventos/edit')).then();
    this.globals.SaveData(moment(item.fecha_inicio).format('YYYY-MM-DD'), 'last-date');
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar evento?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/evt-eventos-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.CargarCalendario();
      });
    });
  }

  ExportarHTMLCompleto(event, item) {
    event.stopPropagation();
    this.globals.ExportarEventoHTMLConCabecera(item);
  }
  ExportarHTMLSimple(event, item) {
    event.stopPropagation();
    this.globals.ExportarEventoHTMLSimple(item);
  }

}
