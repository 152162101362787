import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-config-suscripcion',
  templateUrl: './config-suscripcion.component.html'
})
export class ConfigSuscripcionComponent implements OnInit {
  public value:string = this.globals.GetQueryString('v');
  public config:any = {};
  public categorias:any = [];
  public id_cliente:string = '';
  public email_telefono:string = '';

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    if (!this.value || this.value == '') {
      toastr.error('Se ha producido un error', 'Error');
      return;
    }
    this.value = atob(this.value);
    if (!this.value || this.value == '') {
      toastr.error('Se ha producido un error', 'Error');
      return;
    }
    let value = this.value.split('---')[0];
    let id_cliente = this.value.split('---')[1];
    if (!value || value == '' || !id_cliente || id_cliente == '') {
      toastr.error('Se ha producido un error', 'Error');
      return;
    }
    this.id_cliente = id_cliente;
    this.email_telefono = value;
    this.globals.Api('/pub-suscriptor', {id_cliente: id_cliente, value: value}).subscribe((data:any) => {
      if (!data) {
        this.email_telefono = '';
        this.id_cliente = '';
        return;
      }
    }, (error:any) => {
      toastr.error('Se ha producido un error', 'Error');
    });
    this.globals.Api('/configuracion-public', {id_cliente: id_cliente}).subscribe((data:any) => {
      if (!data) return;
      this.config = data;
    });
    this.categorias = [];
    this.globals.Api('/pub-categorias', {id_cliente: id_cliente, email: value, telefono: value}).subscribe((data:any) => {
      if (!data || !data.length) return;
      this.categorias = data;
      setTimeout(() => {
        if (!$('.chk-cat:checked').length) $('.chk-todas').prop('checked', true);
      }, 200);
    });
  }
  ChangeTodas() {
    if ($('.chk-todas').prop('checked') == true) {
      $('.chk-cat').prop('checked', false);
    }
  }
  ChangeCheck() {
    if (!$('.chk-cat:checked').length) $('.chk-todas').prop('checked', true);
    else {$('.chk-todas').prop('checked', false);}
  }
  GuardarPreferencias() {
    let categorias = [];
    $('.chk-cat:checked').each(function() {
      categorias.push($(this).val());
    });
    this.globals.Api('/pub-suscriptor-edit', {id_cliente: this.id_cliente, value: this.email_telefono, categorias: categorias}).subscribe((data:any) => {
      if (!data || data.error) {
        toastr.error('Se ha producido un error', 'Error');
        return;
      }
      toastr.success('Preferencias guardadas correctamente', 'Guardado');
    }, (error:any) => {
      toastr.error('Se ha producido un error', 'Error');
    });
  }
  CancelarSuscripcion() {
    this.globals.Api('/pub-suscriptor-del', {id_cliente: this.id_cliente, value: this.email_telefono}).subscribe((data:any) => {
      if (!data || data.error) {
        toastr.error('Se ha producido un error', 'Error');
        return;
      }
      toastr.success('Tu suscripción ha sido cancelada correctamente', 'Suscripción cancelada');
    }, (error:any) => {
      toastr.error('Se ha producido un error', 'Error');
    });
  }
}
