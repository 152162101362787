import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let FullCalendar: any;

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styles: [
  ]
})
export class CalendarioComponent implements OnInit {
  public reservas = [];
  public params:any = {
    getbookingairbnb: true,
    filtro: null
  };
  public eventos = [];
  public alojamientos = [];
  public alojamiento:string = '';
  public filtroalojamiento:string = '';
  public cargando:boolean = false;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.ComprobarLicencia();
    this.alojamiento = 'Todos los alojamientos';
    this.CargarAlojamientos();
    this.CargarCalendario();
  }
  CargarAlojamientos() {
    globalThis.cargandousuarios = true;
    this.globals.Api('/alo-alojamientos', {order: 'nombre', filtro: this.filtroalojamiento, limit: 25}).subscribe(data => {
      globalThis.cargandousuarios = false;
      if (!data || data.length == 0) return;
      this.alojamientos = data;
      // if (this.alojamientos.length) this.SetAlojamiento(this.alojamientos[0]);
    }, error => {
      globalThis.cargandousuarios = false;
    });
  }
  BuscarAlojamiento(event) {
    clearInterval(globalThis.idtime);
    globalThis.idtime = setTimeout(() => {
      if (globalThis.cargandousuarios) return;
      this.CargarAlojamientos();
    }, 300);
  }
  SetTodos() {
    for (let item of this.alojamientos) item.active = null;
    this.alojamiento = 'Todos los alojamientos';
    $('#todos-alojamientos').addClass('active');
    this.params.id_alojamiento = null;
    this.Buscar();
  }
  SetAlojamiento(item:any) {
    for (let el of this.alojamientos) el.active = null;
    item.active = true;
    this.alojamiento = item.nombre;
    this.params.id_alojamiento = item.id;
    this.params.admin = true;
    this.CargarCalendario();
  }
  CargarCalendario() {
    setTimeout(() => {
      globalThis.calendarEl = document.getElementById('calendar');
      globalThis.calendar = new FullCalendar.Calendar(globalThis.calendarEl, {
        initialView: 'dayGridMonth',
        locale: 'es',
        height: 'auto',
        initialDate: (globalThis.lastdate ? globalThis.lastdate : null),
        eventClick: (info:any) => {
          if (info.event.extendedProps.data && info.event.extendedProps.data.tipo != 'reserva') return;
          this.Editar(info.event.extendedProps.data);
        },
        eventContent: (e:any) => {
          return {html: e.event.title};
        },
        datesSet: (dateInfo:any) => {
          $.LoadingOverlay("show", {
            background: 'rgba(255, 255, 255, 0.6)',
            imageResizeFactor: 0.5
          });
          globalThis.calendar.removeAllEvents();
          this.eventos = [];
          if (this.reservas && this.reservas.length) {
            this.AddEvents(this.reservas);
          }
          this.params.fecha_inicio = moment(dateInfo.start).format('YYYY-MM-DD');
          this.params.fecha_fin = moment(dateInfo.end).format('YYYY-MM-DD');
          globalThis.lastdate = globalThis.calendar.currentData.currentDate;
          if (this.cargando) {
            $.LoadingOverlay("hide", true);
            return;
          }
          this.cargando = true;
          this.globals.Api('/alo-reservas', this.params).subscribe((data: any) => {
            $.LoadingOverlay("hide", true);
            this.cargando = false;
            globalThis.calendar.removeAllEvents();
            if (!data || !data.length) return;
            this.eventos = data;
            this.AddEvents(this.eventos);
          }, error => {
            $.LoadingOverlay("hide", true);
            this.cargando = false;
          });
        }
      });
      globalThis.calendar.render();

      
      $(".fc-toolbar-title").attr('id', 'fc-datepicker-header').before('<input size="1" style="height: 0px; width:0px; border: 0px;" id="fc-datepicker" value="" />');
      $("#fc-datepicker").datepicker({
        keyboardNavigation: false,
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        autoclose: true,
        weekStart: 1,
        language: 'es',
        format: "MM yyyy",
        viewMode: "months", 
        minViewMode: "months",
        container: '.calendar-container'
      });
      $('#fc-datepicker').datepicker().on('changeDate', function (ev) {
        globalThis.calendar.gotoDate( ev.date );
        $("#fc-datepicker").datepicker('hide');
      });
      $("#fc-datepicker").focus(function () {
          $("#ui-datepicker-div").position({
            my: "center bottom",
            at: "center bottom",
            of: $(this)
          });
      });
      $("#fc-datepicker-header").css('cursor', 'pointer');
      $("#fc-datepicker-header").click(function() {
        $("#fc-datepicker").datepicker('show');
        $(".datepicker").css('margin-top', '40px');
      });
    }, 400);
  }
  AddEvents(events) {
    if (!events || !events.length) return;
    for (let item of events) {
      if (item.tipo == 'reserva') {
        globalThis.calendar.addEvent({
          title: `
            <div class="titulo"><b>#${item.referencia}</b> <span>${item.alojamiento}</span></div>
            ${item.imagen ? `<img src="${item.imagen}">` : ''}
            <span class="cliente">${item.cli_nombre} ${item.cli_apellidos}</span><br>
            <span class="fecha">${item.fecha_llegada_format} - ${item.fecha_salida_format}</span>
            <div class="clearfix"></div>
          `,
          start: moment(item.fecha_llegada).format('YYYY-MM-DD'),
          end: moment(item.fecha_salida).format('YYYY-MM-DD'),
          // display: 'block',
          color: 'var(--color-primary)',
          textColor: '#FFF',
          data: item,
          classNames: ['ev-reserva']
        });
      }
      if (item.tipo == 'booking') {
        globalThis.calendar.addEvent({
          title: `
            <div class="titulo"><b>${item.titulo}</b></div>
            <img src="assets/logo-booking.png">
            <span class="cliente">${item.tipo}</span><br>
            <span class="fecha">${item.fecha_llegada_format} - ${item.fecha_salida_format}</span>
            <div class="clearfix"></div>
          `,
          start: moment(item.fecha_llegada).format('YYYY-MM-DD'),
          end: moment(item.fecha_salida).format('YYYY-MM-DD'),
          // display: 'block',
          textColor: '#FFF',
          data: item,
          classNames: ['ev-reserva', 'ev-reserva-booking']
        });
      }
      if (item.tipo == 'airbnb') {
        globalThis.calendar.addEvent({
          title: `
            <div class="titulo"><b>${item.titulo}</b></div>
            <img src="assets/logo-airbnb.png">
            <span class="cliente">${item.tipo}</span><br>
            <span class="fecha">${item.fecha_llegada_format} - ${item.fecha_salida_format}</span>
            <div class="clearfix"></div>
          `,
          start: moment(item.fecha_llegada).format('YYYY-MM-DD'),
          end: moment(item.fecha_salida).format('YYYY-MM-DD'),
          // display: 'block',
          textColor: '#FFF',
          data: item,
          classNames: ['ev-reserva', 'ev-reserva-airbnb']
        });
      }
    }
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarCalendario();
  }
  Nuevo() {
    if ($.NuevaReserva) $.NuevaReserva();
    // this.globals.passData = '';
    // this.ngZone.run(() => this.router.navigateByUrl('/reservas/add?fromcal=1')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/reservas/edit?fromcal=1')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/alo-reservas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.reservas.splice(this.reservas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
